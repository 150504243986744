import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {CSSTransition} from 'react-transition-group';
import {FormattedMessage} from 'react-intl';

class Text extends Component {
  static defaultProps = {
    visible: false,
    defaultValue: '',
    onChange: null
  };

  static propTypes = {
    visible: PropTypes.bool,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      value: this.props.defaultValue,
      jqInit: false
    };

    this.handleChange = this.handleChange.bind(this);

    this.textareaRef = React.createRef();
  }

  jqInit() {
    $(this.textareaRef.current)
      .qeditor(
        $.extend(
          {
            imageUploadChunkUrl: Q.u('fileUploadChunkUrl'),
            imageUploadChunkCompleteUrl: Q.u('fileUploadChunkCompleteUrl'),
            redirectIframeUrl: Q.u('redirectIframeUrl'),
            suggestUserUrl: Q.u('suggestUserUrl'),
            staticUrl: Q.u('sf')
          },
          {focusOnInit: false, saveOnChange: true}
        )
      )
      .on('change', this.handleChange);
    this.setState({jqInit: true});
  }

  componentDidMount() {
    if (this.props.visible && !this.state.jqInit) {
      this.jqInit();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true && !this.state.jqInit) {
      this.jqInit();
    }
  }

  handleChange(event) {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  }

  render() {
    return (
      <CSSTransition in={this.props.visible} timeout={300} classNames="fade">
        <div id="post_text" className={classNames('form-group fade', {hide: !this.props.visible, in: this.props.visible})} aria-hidden="false">
          <textarea
            ref={this.textareaRef}
            name="text"
            cols="40"
            rows="10"
            data-emojiable="true"
            data-emoji-input="unicode"
            id="id_text"
            defaultValue={this.props.defaultValue}
          />
        </div>
      </CSSTransition>
    );
  }
}

export default Text;
