import React, {PureComponent} from 'react';
import {merge} from '../../utils/I18N';
import i18n from './i18n.json';
import {IntlProvider} from 'react-intl';
import CommunityTourComponent from '../../components/CommunityTour';
import PropTypes from 'prop-types';

const defaultParser = {
  span: (chunks) => <span className="js-date-iso-to-local">{chunks}</span>,
  br: () => <br />,
  p: (...chunks) => <p>{chunks}</p>,
  b: (...chunks) => <b>{chunks}</b>,
  ul: (...chunks) => <ul>{chunks}</ul>,
  li: (...chunks) => <li>{chunks}</li>
};

export default class CommunityTour extends PureComponent {
  state = {};

  static defaultProps = {
    enabled: true,
    src: null,
    tutorial_type: null
  };

  static propTypes = {
    enabled: PropTypes.bool,
    src: PropTypes.string.isRequired,
    tutorial_type: PropTypes.string.isRequired
  };

  render() {
    return (
      <IntlProvider locale={Q.s('locale')} messages={merge(i18n)} defaultRichTextElements={defaultParser}>
        <CommunityTourComponent {...this.props} />
      </IntlProvider>
    );
  }
}
