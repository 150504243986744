require('expose-loader?exposes[]=Components!./components');
require('expose-loader?exposes[]=Containers!./containers');
import {getCookie} from './utils/Cookie';

function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}
$.ajaxSetup({
  beforeSend: function (xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      // eslint-disable-next-line no-undef
      xhr.setRequestHeader('X-CSRFToken', getCookie('_csrf'));
    }
  }
});
