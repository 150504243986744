import React, {Component} from 'react';
import PropTypes from 'prop-types';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import classNames from 'classnames';
import {CSSTransition} from 'react-transition-group';
import Autosuggest from 'react-autosuggest';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import ConfirmModal from '../../ConfirmModal';

const messages = defineMessages({
  placeholder: {
    id: 'post.location.placeholder',
    defaultMessage: 'post.location.placeholder'
  },
  error: {
    id: 'post.location.error',
    defaultMessage: 'post.location.error'
  },
  modalTitle: {
    id: 'modal.title',
    defaultMessage: 'modal.title'
  },
  modalBody: {
    id: 'modal.body',
    defaultMessage: 'modal.body'
  }
});

const _defaultLocation = {
  location: '',
  lat: null,
  lng: null
};

class Location extends Component {
  static defaultProps = {
    visible: false,
    defaultValue: null,
    error: null,
    onChange: null,
    onError: null
  };

  static propTypes = {
    visible: PropTypes.bool,
    defaultValue: PropTypes.object,
    error: PropTypes.object,
    onChange: PropTypes.func,
    onError: PropTypes.func,

    /* Intl */
    intl: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      location: this.props.defaultValue,

      /* Suggestions */
      isSuggesting: false,
      value: '',
      suggestions: [],

      error: null,

      /* Confirm */
      confirm: null
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleConfirmRemove = this.handleConfirmRemove.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSuggestionsChange = this.handleSuggestionsChange.bind(this);
    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.location !== this.state.location && this.props.onChange) {
      this.props.onChange(this.state.location);
    }
    if (this.props.onError && prevState.error !== this.state.error) {
      this.props.onError(this.state.error !== null);
    }
  }

  handleAdd() {
    this.setState({location: {..._defaultLocation}});
  }

  handleConfirmRemove() {
    if (this.state.location !== null && this.state.location !== _defaultLocation) {
      this.setState({confirm: this.handleRemove});
    }
  }

  handleRemove() {
    this.setState({location: null, confirm: null});
  }

  handleSuggestionsChange(event) {
    if (this.state.location && (this.state.location.lat || this.state.location.lng)) {
      // Reset the location and trigger change on parent component
      this.setState({location: Object.assign({}, _defaultLocation), error: null});
    } else {
      this.setState({
        location: Object.assign({}, this.state.location, {location: event.target.value}),
        error: this.props.intl.formatMessage(messages.error)
      });
    }
  }

  handleSuggestionsFetchRequested(obj) {
    if (obj.value.length < parseInt(Q.s('autocompleteMinChars'), 10)) {
      return;
    }
    // Execute request
    const self = this;
    $.ajax({
      type: 'GET',
      url: `${Q.u('localityList')}`,
      data: {
        search: obj.value,
        limit: 5
      }
    })
      .done((result) => {
        self.setState({suggestions: result.results});
      })
      .always(() => self.setState({isSuggesting: false}));
  }

  handleSuggestionsClearRequested() {
    this.setState({suggestions: [], value: ''});
  }

  handleSuggestionSelected(event, {suggestion}) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({location: {location: suggestion.full_address, lat: suggestion.lat, lng: suggestion.lng}, error: null});
  }

  static renderSuggestion(suggestion, {query}) {
    const suggestionText = suggestion.full_address;
    const matches = match(suggestionText, query);
    const parts = parse(suggestionText, matches);
    return (
      <div className="clearfix suggestion-content">
        <div className="text">
          {parts.map((part, index) => {
            return (
              <span className={classNames({highlight: part.highlight})} key={index}>
                {part.text}
              </span>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const suggestionProps = {
      id: 'id_geolocation',
      placeholder: this.props.intl.formatMessage(messages.placeholder),
      value: this.state.location ? this.state.location.location : '',
      onChange: this.handleSuggestionsChange
    };
    return (
      <CSSTransition in={this.props.visible} timeout={300} classNames="fade-in" appear>
        <div id="post_geolocation" className="post_media form-group hide" aria-hidden="false">
          <div id="media_geolocation" className="panel clearfix" aria-hidden="false">
            <div
              className={classNames('panel-heading text-left clearfix', {'add-location': this.state.location === null})}
              onClick={this.state.location === null ? this.handleAdd : null}>
              <i className="media_geolocation_icon scmty-location" />
              <FormattedMessage id="post.location.title" defaultMessage="post.location.title" />
              <button type="button" className="close pull-right" onClick={this.state.location === null ? this.handleAdd : this.handleConfirmRemove}>
                {this.state.location === null ? (
                  <i className="media_geolocation_icon scmty-plus" />
                ) : (
                  <i className="media_geolocation_icon scmty-q-remove" />
                )}
              </button>
            </div>
            <CSSTransition in={this.state.location !== null} timeout={300} classNames="fade" mountOnEnter unmountOnExit appear>
              <div id="geolocation_data" className="panel-body hide">
                <CSSTransition in={this.props.visible && this.state.location !== null} timeout={300} classNames="fade-in" mountOnEnter unmountOnExit>
                  <div className="form-group">
                    <Autosuggest
                      suggestions={this.state.suggestions}
                      onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                      onSuggestionSelected={this.handleSuggestionSelected}
                      highlightFirstSuggestion
                      focusInputOnSuggestionClick={!Q.s('isMobile')}
                      getSuggestionValue={(suggestion) => suggestion.name}
                      renderSuggestion={Location.renderSuggestion}
                      inputProps={suggestionProps}
                    />
                  </div>
                </CSSTransition>
              </div>
            </CSSTransition>
          </div>
          <CSSTransition in={this.state.location !== null && Boolean(this.state.error || this.props.error)} timeout={300} classNames="fade" appear>
            <div className={classNames('input-box hide', {'has-error': this.state.error || this.props.error})}>
              <CSSTransition in={Boolean(this.props.error)} classNames="fade" timeout={300}>
                <span className="help-block hide">{this.props.error ? this.props.error : null}</span>
              </CSSTransition>
              <CSSTransition in={Boolean(this.state.error)} classNames="fade" timeout={300}>
                <span className="help-block hide">{this.state.error ? this.state.error : null}</span>
              </CSSTransition>
            </div>
          </CSSTransition>
          <ConfirmModal
            open={this.state.confirm !== null}
            title={this.props.intl.formatMessage(messages.modalTitle)}
            body={this.props.intl.formatMessage(messages.modalBody)}
            onConfirm={this.state.confirm}
            onClose={() => this.setState({confirm: null})}
          />
        </div>
      </CSSTransition>
    );
  }
}

export default injectIntl(Location);
