import React, {PureComponent} from 'react';
import {IntlProvider} from 'react-intl';
import PropTypes from 'prop-types';
import i18n from './i18n';
import PostComponent from '../../components/Post';
import {merge} from '../../utils/I18N';

export default class Post extends PureComponent {
  state = {};

  static defaultProps = {
    open: false,
    id: null,
    avatar: null,
    cancel: null,
    addressing: null
  };

  static propTypes = {
    /* Other */
    open: PropTypes.bool,
    username: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    cancel: PropTypes.string,
    id: PropTypes.string,
    addressing: PropTypes.array
  };

  render() {
    return (
      <IntlProvider locale={Q.s('locale')} messages={merge(i18n)}>
        <PostComponent {...this.props} />
      </IntlProvider>
    );
  }
}
