import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {CSSTransition} from 'react-transition-group';
import {ReactSortable} from 'react-sortablejs';
import Spinner from 'react-spinner-material';
import ConfirmModal from '../../ConfirmModal';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import {formatError} from '../../../utils/Http';
import {capitalize} from '../../../utils/String';

const messages = defineMessages({
  commonError: {
    id: 'common.error',
    defaultMessage: 'common.error'
  },
  maxFileSize: {
    id: 'post.media.maxFileSize',
    defaultMessage: 'post.media.maxFileSize'
  },
  acceptFileTypes: {
    id: 'post.media.acceptFileTypes',
    defaultMessage: 'post.media.acceptFileTypes'
  },
  imagesTitle: {
    id: 'post.media.images.title',
    defaultMessage: 'post.media.images.title'
  },
  videoTitle: {
    id: 'post.media.video.title',
    defaultMessage: 'post.media.video.title'
  },
  vimeoError: {
    id: 'post.media.video.vimeo.error',
    defaultMessage: 'post.media.video.vimeo.error'
  },
  vimeoWarning: {
    id: 'post.media.video.vimeo.warning',
    defaultMessage: 'post.media.video.vimeo.warning'
  },
  documentsTitle: {
    id: 'post.media.documents.title',
    defaultMessage: 'post.media.documents.title'
  },
  linkTitle: {
    id: 'post.media.link.title',
    defaultMessage: 'post.media.link.title'
  },
  invalidUrl: {
    id: 'post.media.url.invalid',
    defaultMessage: 'post.media.url.invalid'
  },
  modalTitle: {
    id: 'modal.title',
    defaultMessage: 'modal.title'
  },
  modalBody: {
    id: 'modal.body',
    defaultMessage: 'modal.body'
  }
});

const MEDIA_TYPE_IMAGE = 'image';
const MEDIA_TYPE_VIDEO = 'video';
const MEDIA_TYPE_LINK = 'link';
const MEDIA_TYPE_DOCUMENT = 'doc';

const CORS = $.support.cors ? 1 : 0;
const ACCEPTED_FILE_TYPES = {
  image: /(\.|\/)(gif|jpe?g|png)$/i,
  doc: /(\.|\/)(pdf)$/i
};
const MAX_FILE_SIZE = {
  image: 5242880, // 5MB
  doc: 52428800 // 50MB
};

const REGEXPS = {
  [MEDIA_TYPE_VIDEO]: new RegExp(
    '^(http[s]?:\\/\\/youtu\\.be\\/[a-zA-Z0-9-_]+$|http[s]?:\\/\\/www\\.youtube\\.com\\/watch\\?v=[a-zA-Z0-9-_]+($|&.+))|http[s]?:\\/\\/(vimeo\\.com\\/[0-9]+$|player\\.vimeo\\.com\\/video\\/[0-9]*($|\\?title=.+))'
  ),
  [MEDIA_TYPE_LINK]: new RegExp(
    '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
  )
};

const _defaultState = {
  panels: [],
  imageFileuploadProgress: [],
  docFileuploadProgress: [],

  /* Confirm */
  confirm: null,

  /* url fetch */
  isCreatingLink: false,
  linkUrl: '',
  linkUrlError: null,
  isCreatingVideo: false,
  videoUrl: '',
  videoUrlError: null,

  /* Vimeo */
  vimeoProgress: null,
  vimeoFilename: null,
  vimeoError: null,
  vimeoWarning: null,
  vimeoDrag: false,
  isVimeoPreview: false
};

const getOpenedPanels = (medias) => {
  return [
    ...new Set(
      medias.map((media) =>
        media.type === 'url' ? (media.embed && media.embed.metadata.type === 'video' ? MEDIA_TYPE_VIDEO : MEDIA_TYPE_LINK) : media.type
      )
    )
  ];
};

class Media extends Component {
  static defaultProps = {
    visible: false,
    defaultValue: null,
    onChange: null,
    onProgressStart: null,
    onProgressEnd: null,
    onError: null
  };

  static propTypes = {
    visible: PropTypes.bool,
    defaultValue: PropTypes.array,
    onChange: PropTypes.func,
    onProgressStart: PropTypes.func,
    onProgressEnd: PropTypes.func,
    onError: PropTypes.func,

    /* Intl */
    intl: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    const medias = this.props.defaultValue ? this.props.defaultValue : [];
    const panels = getOpenedPanels(medias);

    this.state = Object.assign({}, _defaultState, {
      images: medias.filter((media) => media.type === MEDIA_TYPE_IMAGE),
      videos: medias
        .filter((media) => media.type === 'url' && media.embed && media.embed.metadata.type === MEDIA_TYPE_VIDEO)
        .map((media) => {
          media.embed.metadata.stored = true;
          return media;
        }),
      docs: medias.filter((media) => media.type === MEDIA_TYPE_DOCUMENT),
      links: medias
        .filter((media) => media.type === 'url' && media.embed && media.embed.metadata.type !== MEDIA_TYPE_VIDEO)
        .map((media) => {
          media.embed.metadata.stored = true;
          return media;
        }),
      panels,
      url: '',
      jqInit: false,
      imageFileuploadProgress: [],
      docFileuploadProgress: []
    });

    this.handleClick = this.handleClick.bind(this);
    this.handleConfirmClose = this.handleConfirmClose.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleFileUploadAdd = this.handleFileUploadAdd.bind(this);
    this.handleFileUploadFail = this.handleFileUploadFail.bind(this);
    this.handleFileUploadChunkDone = this.handleFileUploadChunkDone.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckCreate = this.handleCheckCreate.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleChooseImage = this.handleChooseImage.bind(this);
    this.handleVimeoDragOver = this.handleVimeoDragOver.bind(this);
    this.handleVimeoDragLeave = this.handleVimeoDragLeave.bind(this);
    this.handleVimeoFileSelect = this.handleVimeoFileSelect.bind(this);

    this.imagesUploadRef = React.createRef();
    this.docsUploadRef = React.createRef();
  }

  initFileUpload(ref, type) {
    const self = this;
    $(ref.current)
      .fileupload({
        url: Q.u('fileUploadChunkUrl'),
        redirect: Q.u('redirectIframeUrl'),
        formData: [{name: 'cors', value: CORS}],
        dataType: 'json',
        dropZone: $(`#media_${type}`)
          .find('.drop-zone')
          .on('dragover', function (evt) {
            evt.stopPropagation();
            evt.preventDefault();
            if (
              evt.originalEvent.dataTransfer.types.find(function (el) {
                return el === 'Files';
              })
            ) {
              $(this).addClass('hover');
            }
          })
          .on('dragleave drop', function (evt) {
            evt.stopPropagation();
            evt.preventDefault();
            if (
              evt.originalEvent.dataTransfer.types.find(function (el) {
                return el === 'Files';
              })
            ) {
              $(this).removeClass('hover');
            }
          }),
        sequentialUploads: true,
        acceptFileTypes: ACCEPTED_FILE_TYPES[type],
        disableValidation: Q.s('isMobile'),
        maxFileSize: MAX_FILE_SIZE[type],
        i18n: function (key) {
          if (key === 'maxFileSize') {
            return self.props.intl.formatMessage(messages.maxFileSize, {size: MAX_FILE_SIZE[type] / 1024 / 1024});
          } else if (key === 'acceptFileTypes') {
            return self.props.intl.formatMessage(messages.acceptFileTypes, {
              types: ACCEPTED_FILE_TYPES[type].toString().replace('/(\\.|\\/)(', '').replace(')$/i', '').replace(/\|/g, ', ').replace(/\?/g, '')
            });
          }
          return self.props.intl.formatMessage(messages[key]);
        },
        maxChunkSize: 204800,
        done: function (e, data) {
          // Called when the file has completely uploaded
          data.formData = data.formData.splice(1); // Removed upload_id from formData for next chuck upload
          MD5(data.files[0], 100000, function (md5) {
            const uploadId = data.result.upload_id;
            $.ajax({
              type: 'POST',
              url: Q.u('fileUploadChunkCompleteUrl'),
              data: {
                upload_id: uploadId,
                md5: md5,
                cors: CORS
              },
              dataType: 'json'
            })
              .done(function (result) {
                data.result = result;
                self.handleFileUploadDone(e, data);
                self.props.onProgressEnd && self.props.onProgressEnd(`file_${btoa(unescape(encodeURIComponent(data.files[0].name)))}`);
              })
              .fail(function () {
                self.handleFileUploadFail(e, data);
              });
          });
        }
      })
      .on('fileuploadadd', this.handleFileUploadAdd)
      .on('fileuploadfail', this.handleFileUploadFail)
      .on('fileuploadchunkdone', this.handleFileUploadChunkDone);
  }

  jqInit() {
    if (!Q.s('isAndroidFixUploadMedia')) {
      this.initFileUpload(this.imagesUploadRef, MEDIA_TYPE_IMAGE);
      this.initFileUpload(this.docsUploadRef, MEDIA_TYPE_DOCUMENT);
    }
    this.setState({jqInit: true});
  }

  componentDidMount() {
    if (this.props.visible && !this.state.jqInit) {
      this.jqInit();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible === false && this.props.visible === true && !this.state.jqInit) {
      this.jqInit();
    }
    if (prevState.panels !== this.state.panels) {
      !prevState.panels.includes(MEDIA_TYPE_IMAGE) && this.state.panels.includes(MEDIA_TYPE_IMAGE) && $(this.imagesUploadRef.current).click();
      !prevState.panels.includes(MEDIA_TYPE_DOCUMENT) && this.state.panels.includes(MEDIA_TYPE_DOCUMENT) && $(this.docsUploadRef.current).click();
    }
    if (
      (prevState.images !== this.state.images ||
        prevState.videos !== this.state.videos ||
        prevState.docs !== this.state.docs ||
        prevState.links !== this.state.links) &&
      this.props.onChange
    ) {
      this.props.onChange([...this.state.images, ...this.state.videos, ...this.state.docs, ...this.state.links]);
    }
    if (this.props.onError && (prevState.urlError !== this.state.urlError || prevState.vimeoError !== this.state.vimeoError)) {
      this.props.onError(this.state.urlError !== null || this.state.vimeoError !== null);
    }
  }

  handleClick(panel) {
    return () => this.setState({panels: [...this.state.panels, panel]});
  }

  handleConfirmClose(panel) {
    const self = this;
    return () => {
      if (self.state[`${panel}s`].length === 0) {
        self.handleClose(panel)();
      } else {
        self.setState({confirm: self.handleClose(panel)});
      }
    };
  }

  handleClose(panel) {
    const self = this;
    return () => {
      self.setState({..._defaultState, panels: this.state.panels.filter((p) => p !== panel), [`${panel}s`]: []});
    };
  }

  handleDelete(panel, id) {
    const self = this;
    const media = `${panel}s`;
    return () => {
      const newState = {
        [media]: self.state[media].filter((m) => m.id !== id),
        panels: self.state.panels
      };
      if (newState[media].length === 0) {
        newState.panels = newState.panels.filter((p) => p !== panel);
      }

      this.setState(Object.assign({}, _defaultState, newState));
    };
  }

  /**
   * Handler For add event of file upload
   *
   * BEHAVIOUR:
   * Hide the upload buttons and create the progressbar for the file upload
   *
   * @param evt
   * @param data
   * @private
   */
  handleFileUploadAdd(evt, data) {
    const self = this;
    const fileKey = `file_${btoa(unescape(encodeURIComponent(data.files[0].name)))}`;
    this.props.onProgressStart && this.props.onProgressStart(fileKey);
    data
      .process(function () {
        return $(evt.currentTarget).fileupload('process', data);
      })
      .always(function () {
        data.files[0].progress = 0;
        const progressState = `${data.paramName === 'document' ? MEDIA_TYPE_DOCUMENT : MEDIA_TYPE_IMAGE}FileuploadProgress`;
        self.setState({[progressState]: [...self.state[progressState], data.files[0]]});
        if (data.files.error) {
          self.props.onProgressEnd && self.props.onProgressEnd(fileKey);
          setTimeout(function () {
            self.setState({[progressState]: self.state[progressState].filter((file) => data.files[0].name !== file.name)});
          }, 2000);
        }
      });
  }

  /**
   * Handler For done event of file upload
   *
   * BEHAVIOUR:
   * Update the media object has for the form and run the initialization of the preview area for the image box
   *
   * @param evt
   * @param data
   * @private
   */
  handleFileUploadDone(evt, data) {
    const self = this;
    const progressState = `${data.paramName[0] === 'document' ? MEDIA_TYPE_DOCUMENT : MEDIA_TYPE_IMAGE}FileuploadProgress`;
    const media = `${data.paramName[0] === 'document' ? MEDIA_TYPE_DOCUMENT : MEDIA_TYPE_IMAGE}s`;
    if (data.result.id) {
      this.setState({
        [progressState]: this.state[progressState].filter((file) => data.files[0].name !== file.name),
        [media]: [...this.state[media], data.result]
      });
    } else {
      this.setState({
        [progressState]: this.state[progressState].map((file) => {
          if (data.files[0].name === file.name) {
            file.error = data.result.message;
          }
          return file;
        }),
        [media]: [...this.state[media], data.result]
      });
      setTimeout(function () {
        self.setState({[progressState]: self.state[progressState].filter((file) => data.files[0].name !== file.name)});
      }, 3000);
    }
  }

  /**
   * Handle Error for aborting requests
   *
   * BEHAVIOUR:
   * Render errors on the upload area and switch back to buttons for upload
   *
   * @param e
   * @param data
   * @private
   */
  handleFileUploadFail(e, data) {
    const self = this;
    const progressState = `${data.paramName[0] === 'document' ? MEDIA_TYPE_DOCUMENT : MEDIA_TYPE_IMAGE}FileuploadProgress`;
    this.setState({
      [progressState]: this.state[progressState].map((file) => {
        if (data.files[0].name === file.name) {
          file.error = this.props.intl.formatMessage(messages.commonError);
        }
        return file;
      })
    });
    setTimeout(function () {
      self.setState({[progressState]: self.state[progressState].filter((file) => data.files[0].name !== file.name)});
    }, 3000);

    if (data.formData.findIndex((d) => d.name === 'upload_id') !== -1) {
      this.props.onProgressEnd && this.props.onProgressEnd(`file_${btoa(unescape(encodeURIComponent(data.files[0].name)))}`);
    }
  }

  /**
   * Handler For chunk done event of file upload
   *
   * BEHAVIOUR:
   * update the progressbar
   *
   * @param e
   * @param data
   * @private
   */
  handleFileUploadChunkDone(e, data) {
    // Called after uploading each chunk
    if (data.formData.length < 2) {
      data.formData.push({name: 'upload_id', value: data.result.upload_id});
    }
    const progressState = `${data.paramName[0] === 'document' ? MEDIA_TYPE_DOCUMENT : MEDIA_TYPE_IMAGE}FileuploadProgress`;
    const progress = Math.floor((data.loaded / data.total) * 100);
    this.setState({
      [progressState]: this.state[progressState].map((file) => {
        if (data.files[0].name === file.name) {
          file.progress = progress;
        }
        return file;
      })
    });
  }

  handleMediaSort(event, ui) {
    var id = ui.item.data('id'),
      nextId = ui.item.next().data('id');
    if (nextId) {
      this.mediaValuesBox.find('input[value="' + id + '"]').insertBefore(this.mediaValuesBox.find('input[value="' + nextId + '"]'));
    } else {
      this.mediaValuesBox.append(this.mediaValuesBox.find('input[value="' + id + '"]'));
    }
  }

  /**
   * Handler for media input change
   *
   * @private
   * @param event
   */
  handleChange(panel) {
    const self = this;
    return (event) => {
      self.setState({
        [`${panel}Url`]: event.target.value,
        [`${panel}UrlError`]: !REGEXPS[panel].test(event.target.value) ? this.props.intl.formatMessage(messages.invalidUrl) : null
      });
    };
  }

  handleCheckCreate(panel) {
    const self = this;
    return (event) => {
      setTimeout(() => {
        if (self.state[`${panel}UrlError`] === null && self.state[`${panel}Url`]) {
          self.handleCreate(panel)(event);
        }
      }, 0);
    };
  }

  handleKeydown(panel) {
    const self = this;
    return (event) => {
      if (event.keyCode === 13) {
        // Prevent enter keycode because question title cannot contain break spaces
        event.preventDefault();
        event.stopPropagation();
        self.handleCreate(panel)(event);
        return false;
      }
    };
  }

  /**
   * Handler for media input create
   *
   * BEHAVIOUR:
   * Perform scraping of the url passed as value and display the media content
   * on the display area.
   * After the scraping pre populate the media hidden attributes
   *
   * @private
   */
  handleCreate(panel) {
    const self = this;
    return (event, type) => {
      type = type || 'url';
      self.setState({[`isCreating${capitalize(panel)}`]: true});
      this.props.onProgressStart && this.props.onProgressStart('media_create');
      $.ajax({
        url: Q.u('media'),
        type: 'POST',
        data: {url: this.state[`${panel}Url`], type}
      })
        .done(function (data) {
          if (data.embed && data.embed.metadata && data.embed.metadata.images && data.embed.metadata.images.length > 0) {
            data.embed.metadata.images[0].selected = true;
          }
          self.setState({
            [`${panel}Url`]: '',
            [`${panel}UrlError`]: null,
            [`${panel}s`]: [...self.state[`${panel}s`], data],
            [`isCreating${capitalize(panel)}`]: false
          });
        })
        .fail((e) => {
          const {urlError, error} = formatError(e.responseJSON.errors, {});
          self.setState({[`${panel}UrlError`]: urlError ? urlError.error : error, [`isCreating${capitalize(panel)}`]: false});
        })
        .always(() => {
          self.props.onProgressEnd && self.props.onProgressEnd('media_create');
        });
    };
  }

  handleChooseImage(media, index) {
    return () => {
      let imageUrl = null;
      media.embed = Object.assign({}, media.embed);
      media.embed.metadata.images = media.embed.metadata.images.map((c, i) => {
        c.selected = i === index;
        if (c.selected) {
          imageUrl = c.url;
        }
        return c;
      });
      this.setState({medias: [media]});

      // Update media state on server
      $.ajax({
        url: `${Q.u('media')}${media.id}/`,
        type: 'PUT',
        data: {image: imageUrl}
      });
    };
  }

  handleVimeoFileSelect(event) {
    event.stopPropagation();
    event.preventDefault();
    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    // Check if ONE file is passed
    if (files.length === 0) return;
    if (files.length > 1) {
      this.setState({vimeoError: this.props.intl.formatMessage(messages.vimeoError)});
      return;
    }
    // Check if file type is video
    if (!files[0].type.toLowerCase().startsWith('video/')) {
      this.setState({vimeoError: this.props.intl.formatMessage(messages.acceptFileTypes, {types: 'video'})});
      return;
    }
    // Reset the progress bar and show it
    let filename = files[0].name;
    this.setState({vimeoProgress: 0, vimeoFilename: filename, vimeoDrag: false});
    const self = this;
    this.props.onProgressStart && this.props.onProgressStart(`file_${btoa(unescape(encodeURIComponent(filename)))}`);
    new VimeoUpload({
      name: files[0].name,
      description: '',
      file: files[0],
      checkPreview: true,
      checkAvailable: false,
      token: Q.s('vimeoToken'),
      onError: function (data) {
        let json = JSON.parse(data);
        self.setState({
          vimeoWarning: null,
          vimeoFilename: null,
          vimeoProgress: null,
          vimeoError: (
            <Fragment>
              {json.error}
              <br />
              <small>
                ({json.error_code} - {json.developer_message})
              </small>
              }
            </Fragment>
          )
        });
        self.props.onProgressEnd && self.props.onProgressEnd(`file_${btoa(unescape(encodeURIComponent(filename)))}`);
      },
      onProgress: function (data) {
        self.setState({vimeoProgress: data.loaded / data.total, vimeoFilename: filename});
      },
      onComplete: function (videoId) {
        self.setState({
          vimeoWarning: self.props.intl.formatMessage(messages.vimeoWarning),
          vimeoError: null,
          vimeoProgress: null,
          vimeoFilename: null,
          isVimeoPreview: true,
          videoUrl: `https://vimeo.com/${videoId}`
        });
        self.props.onProgressEnd && self.props.onProgressEnd(`file_${btoa(unescape(encodeURIComponent(filename)))}`);
        self.handleCreate(MEDIA_TYPE_VIDEO)({}, 'vimeo');
      },
      onPreview: function (pictures) {
        let thumbnail = pictures.sizes[pictures.sizes.length - 1].link;
        const {medias} = self.state;
        if (medias.length === 1) {
          medias[0].image = thumbnail;
        }
        self.setState({medias, vimeoWarning: null, isVimeoPreview: false});
      }
    }).upload();
  }

  handleVimeoDragOver(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    if (evt.target.type === 'file') {
      this.setState({vimeoDrag: true});
    }
  }

  handleVimeoDragLeave(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    if (evt.target.type === 'file') {
      this.setState({vimeoDrag: false});
    }
  }

  static renderFileupload(file) {
    return (
      <div className="clearfix" key={file.name}>
        {file.error ? (
          <div className="alert alert-danger">
            <strong>{file.name}</strong>&nbsp;{file.error}
          </div>
        ) : (
          <Fragment>
            <div className="file-name">{file.name}</div>
            <div className="pull-right">
              <button type="button" className="btn btn-sm btn-danger cancel-upload">
                <i className="scmty-cross" />
              </button>
            </div>
            <div className="progress">
              <div className="progress-bar" style={{width: `${file.progress}%`}} />
            </div>
          </Fragment>
        )}
      </div>
    );
  }

  renderMedia(media) {
    if (media.type === MEDIA_TYPE_IMAGE) {
      return (
        <div key={media.id} className="text-center js-media" data-id={media.id}>
          <div className="thumbnail" style={{backgroundImage: `url('${media.image}')`}}>
            <button className="btn-link close" onClick={this.handleDelete(MEDIA_TYPE_IMAGE, media.id)}>
              <i className="scmty-cross"></i>
            </button>
          </div>
        </div>
      );
    } else if (media.type === MEDIA_TYPE_DOCUMENT) {
      return (
        <div key={media.id} className="row js-media mt20" data-id={media.id}>
          <div className="col-md-4">
            <div className="thumbnail dc">
              <img className="img-responsive" src={media.image} />
            </div>
          </div>
          <div className="col-md-8">
            <h4>
              {media.title}{' '}
              <button type="button" className="btn btn-link close" onClick={this.handleDelete(MEDIA_TYPE_DOCUMENT, media.id)}>
                <i className="scmty-cross" />
              </button>
            </h4>
            <a className="btn btn-default btn-sm mt10 mr10 text-uppercase disabled" href={media.url} target="_blank" download="">
              <FormattedMessage id="post.media.documents.download" defaultMessage="post.media.documents.download" />
            </a>
            <a className="btn btn-default btn-sm mt10 text-uppercase disabled" href={media.url} target="_blank">
              <FormattedMessage id="post.media.documents.online" defaultMessage="post.media.documents.online" />
            </a>
          </div>
        </div>
      );
    } else if (media.type === 'url' && media.embed && media.embed.metadata.type == 'video') {
      return (
        <div key={media.id} className="row js-media mt20" data-id={media.id}>
          <button type="button" className="close" onClick={this.handleDelete(MEDIA_TYPE_VIDEO, media.id)}>
            <i className="scmty-cross" />
          </button>
          <div className="col-md-4">
            <div className={classNames('thumbnail dc', {'spinner-center-50': media.type === 'vimeo' && this.state.isVimeoPreview})}>
              <img className="img-responsive" src={media.image} />
              {media.type === 'vimeo' && this.state.isVimeoPreview && <Spinner radius={50} stroke={3} visible={true} color="#FFF" />}
            </div>
          </div>
          <div className="col-md-8">
            <h4>{media.title}</h4>
            <p className="url">{media.url}</p>
            <p>{media.description}</p>
          </div>
        </div>
      );
    } else if (media.type === 'url') {
      let images = media.embed.metadata.stored ? null : media.embed.metadata.images;
      const selectedIndex = images ? images.findIndex((i) => i.selected) : 0;
      return (
        <div key={media.id} className="row js-media mt20" data-id={media.id}>
          <button type="button" className="close" onClick={this.handleDelete(MEDIA_TYPE_LINK, media.id)}>
            <i className="scmty-cross" />
          </button>
          <div className="col-md-4">
            {images ? (
              images.map((image, index) => (
                <div key={`${media.id}_choice_${index}`} className={classNames('thumbnail dc', {hide: !image.selected})}>
                  <img className="img-responsive" src={image.url} />
                </div>
              ))
            ) : (
              <div className="thumbnail dc">
                <img className="img-responsive" src={media.image} />
              </div>
            )}
          </div>
          <div className="col-md-8">
            <h4>{media.title}</h4>
            <p className="url">{media.url}</p>
            <p>{media.description}</p>
            {images && (
              <div className="choice">
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-xs btn-default prev"
                    disabled={selectedIndex === 0}
                    onClick={this.handleChooseImage(media, selectedIndex - 1)}>
                    <span className="scmty-q-arrow-left" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-xs btn-default next"
                    disabled={selectedIndex === images.length - 1}
                    onClick={this.handleChooseImage(media, selectedIndex + 1)}>
                    <span className="scmty-q-arrow-right" />
                  </button>
                </div>
                <span className="meta">
                  <FormattedMessage
                    id="post.media.link.miniature"
                    defaultMessage="post.media.link.miniature"
                    values={{current: selectedIndex + 1, total: images.length}}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }
    return <div />;
  }

  renderActions() {
    return (
      <CSSTransition in={this.props.visible} timeout={300} classNames="fade-in" appear>
        <div className="media-group hide">
          <CSSTransition in={this.props.visible && this.state.panels.length === 0} timeout={300} classNames="fade" appear>
            <div className="post_section_title">
              <FormattedMessage id="post.media.title" defaultMessage="post.media.title" />
            </div>
          </CSSTransition>
          <div className={classNames('media-group-actions', {active: this.state.panels.length > 0})}>
            <button
              type="button"
              title={this.props.intl.formatMessage(messages.imagesTitle)}
              className="btn btn-link js-fileupload"
              onClick={this.handleClick(MEDIA_TYPE_IMAGE)}
              data-rel="media_images"
              disabled={this.state.panels.includes(MEDIA_TYPE_IMAGE)}>
              <span className="scmty-q-camera" />
            </button>
            <button
              type="button"
              title={this.props.intl.formatMessage(messages.videoTitle)}
              className="btn btn-link"
              onClick={this.handleClick(MEDIA_TYPE_VIDEO)}
              data-rel="media_video"
              disabled={this.state.panels.includes(MEDIA_TYPE_VIDEO)}>
              <span className="scmty-q-video" />
            </button>
            <button
              type="button"
              title={this.props.intl.formatMessage(messages.documentsTitle)}
              className="btn btn-link js-fileupload"
              onClick={this.handleClick(MEDIA_TYPE_DOCUMENT)}
              data-rel="media_documents"
              disabled={this.state.panels.includes(MEDIA_TYPE_DOCUMENT)}>
              <span className="scmty-pdf_doc" />
            </button>
            <button
              type="button"
              title={this.props.intl.formatMessage(messages.linkTitle)}
              className="btn btn-link"
              onClick={this.handleClick(MEDIA_TYPE_LINK)}
              data-rel="media_link"
              disabled={this.state.panels.includes(MEDIA_TYPE_LINK)}>
              <span className="scmty-q-link" />
            </button>
          </div>
        </div>
      </CSSTransition>
    );
  }

  renderImagePanel() {
    const {images} = this.state;
    return (
      <CSSTransition
        key={MEDIA_TYPE_IMAGE}
        in={this.props.visible && this.state.panels.includes(MEDIA_TYPE_IMAGE)}
        timeout={300}
        classNames="fade-in"
        appear>
        <div id="media_images" className="panel clearfix hide" data-mediatype="images">
          <div className="panel-heading clearfix">
            <span className="scmty-q-camera" />
            &nbsp;
            <FormattedMessage id="post.media.images.title" defaultMessage="post.media.images.title" />
            <button type="button" className="close pull-right" onClick={this.handleConfirmClose(MEDIA_TYPE_IMAGE)}>
              <i className="scmty-cross" />
            </button>
          </div>
          <div className="panel-body drop-zone">
            <ReactSortable list={images} setList={(newSort) => this.setState({images: newSort})} className="media-preview" component="div">
              {images.map((media) => this.renderMedia(media))}
            </ReactSortable>
            <div className="progress-area">{this.state.imageFileuploadProgress.map((file) => Media.renderFileupload(file))}</div>
            <div className="upload-area">
              <button type="button" className="btn btn-default btn-upload btn-block">
                <i className="scmty-plus" /> <FormattedMessage id="post.media.images.add" defaultMessage="post.media.images.add" />
                <input className="images-upload" type="file" name="image" multiple accept=".gif, .jpg, jpeg, .png" ref={this.imagesUploadRef} />
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }

  renderVideoPanel() {
    const vimeoDragListeners = Q.s('canUploadVideo')
      ? {onDragLeave: this.handleVimeoDragLeave, onDrop: this.handleVimeoFileSelect, onDragOver: this.handleVimeoDragOver}
      : {};
    return (
      <CSSTransition
        key={MEDIA_TYPE_VIDEO}
        in={this.props.visible && this.state.panels.includes(MEDIA_TYPE_VIDEO)}
        timeout={300}
        classNames="fade-in"
        appear>
        <div id="media_video" className="panel clearfix hide" data-mediatype="video">
          <div className="panel-heading clearfix">
            <span className="scmty-q-video" />
            &nbsp;
            <FormattedMessage id="post.media.video.title" defaultMessage="post.media.video.title" />
            <button type="button" className="close pull-right" onClick={this.handleConfirmClose(MEDIA_TYPE_VIDEO)}>
              <i className="scmty-cross" />
            </button>
          </div>
          <div
            className={classNames('panel-body drop-zone', {
              'has-error': this.state.videoUrlError !== null || this.state.vimeoError !== null,
              'has-warning': this.state.vimeoWarning !== null,
              hover: this.state.vimeoDrag
            })}
            {...vimeoDragListeners}>
            <div className="clearfix input-box">
              <input
                type="text"
                name="url"
                placeholder="http://"
                value={this.state.videoUrl}
                onChange={this.handleChange(MEDIA_TYPE_VIDEO)}
                onPaste={this.handleCheckCreate(MEDIA_TYPE_VIDEO)}
                onBlur={this.handleCheckCreate(MEDIA_TYPE_VIDEO)}
                onKeyDown={this.handleKeydown(MEDIA_TYPE_VIDEO)}
                disabled={this.state.isCreatingVideo}
              />
              <CSSTransition
                in={
                  this.props.visible &&
                  this.state.panels.includes(MEDIA_TYPE_VIDEO) &&
                  this.state.videoUrl !== '' &&
                  this.state.videoUrlError === null
                }
                timeout={300}
                classNames="fade-in">
                <button type="button" className="btn-link go hide" onClick={this.handleCreate} disabled={this.state.isCreatingVideo}>
                  {this.state.isCreatingVideo ? <Spinner radius={20} stroke={2} visible={true} /> : <span className="scmty-play" />}
                </button>
              </CSSTransition>
            </div>
            <CSSTransition
              in={
                (this.props.visible && this.state.panels.includes(MEDIA_TYPE_VIDEO) && this.state.videoUrlError !== null) ||
                this.state.vimeoError !== null ||
                this.state.vimeoWarning !== null
              }
              timeout={300}
              classNames="fade-in">
              <span className="help-block hide fade">{this.state.videoUrlError || this.state.vimeoError || this.state.vimeoWarning}</span>
            </CSSTransition>
            <div className="media-preview">{this.state.videos.map((media) => this.renderMedia(media))}</div>
            {Q.s('canUploadVideo') && (
              <Fragment>
                <CSSTransition in={this.state.vimeoFilename !== null && this.state.vimeoProgress !== null} timeout={300} classNames="fade-in">
                  <div className="progress-area hide">
                    <div className="clearfix">
                      <div className="file-name">{this.state.vimeoFilename}</div>
                      <div className="progress">
                        <div className="progress-bar" style={{width: `${this.state.vimeoProgress}%`}} />
                      </div>
                    </div>
                  </div>
                </CSSTransition>
                <div className="upload-area">
                  <div className="align-center" style={{paddingBottom: 7}}>
                    or
                  </div>
                  <button type="button" className="btn btn-default btn-upload btn-block">
                    <i className="scmty-plus" /> <FormattedMessage id="post.media.video.add" defaultMessage="post.media.video.add" />
                    <input className="video-upload" type="file" name="video" accept="video/*,.mkv,.avi,.flv" onChange={this.handleVimeoFileSelect} />
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </CSSTransition>
    );
  }

  renderDocumentPanel() {
    const {docs} = this.state;
    return (
      <CSSTransition
        key={MEDIA_TYPE_DOCUMENT}
        in={this.props.visible && this.state.panels.includes(MEDIA_TYPE_DOCUMENT)}
        timeout={300}
        classNames="fade-in"
        appear>
        <div id="media_documents" className="panel clearfix hide" data-mediatype="documents">
          <div className="panel-heading clearfix">
            <span className="scmty-pdf_doc" />
            &nbsp;
            <FormattedMessage id="post.media.documents.title" defaultMessage="post.media.documents.title" />
            <button type="button" className="close pull-right" onClick={this.handleConfirmClose(MEDIA_TYPE_DOCUMENT)}>
              <i className="scmty-cross" />
            </button>
          </div>
          <div className="panel-body drop-zone">
            <ReactSortable list={docs} setList={(newSort) => this.setState({docs: newSort})} tag="div" className="media-preview">
              {docs.map((media) => this.renderMedia(media))}
            </ReactSortable>
            <div className="progress-area">{this.state.docFileuploadProgress.map((file) => Media.renderFileupload(file))}</div>
            <div className="upload-area">
              <button type="button" className="btn btn-default btn-upload btn-block">
                <i className="scmty-plus" /> <FormattedMessage id="post.media.documents.add" defaultMessage="post.media.documents.add" />
                <input className="documents-upload" type="file" name="document" multiple accept="application/pdf" ref={this.docsUploadRef} />
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }

  renderLinkPanel() {
    return (
      <CSSTransition
        key={MEDIA_TYPE_LINK}
        in={this.props.visible && this.state.panels.includes(MEDIA_TYPE_LINK)}
        timeout={300}
        classNames="fade-in"
        appear>
        <div id="media_link" className="panel clearfix hide" data-mediatype="link">
          <div className="panel-heading clearfix">
            <span className="scmty-q-link" />
            &nbsp;
            <FormattedMessage id="post.media.link.title" defaultMessage="post.media.link.title" />
            <button type="button" className="close pull-right" onClick={this.handleConfirmClose(MEDIA_TYPE_LINK)}>
              <i className="scmty-cross" />
            </button>
          </div>
          <div className={classNames('panel-body', {'has-error': this.state.urlError !== null})}>
            <div className="clearfix input-box">
              <input
                type="text"
                name="url"
                placeholder="https://"
                value={this.state.linkUrl}
                onChange={this.handleChange(MEDIA_TYPE_LINK)}
                onPaste={this.handleCheckCreate(MEDIA_TYPE_LINK)}
                onBlur={this.handleCheckCreate(MEDIA_TYPE_LINK)}
                onKeyDown={this.handleKeydown(MEDIA_TYPE_LINK)}
                disabled={this.state.isCreatingLink}
              />
              <CSSTransition
                in={
                  this.props.visible && this.state.panels.includes(MEDIA_TYPE_LINK) && this.state.linkUrl !== '' && this.state.linkUrlError === null
                }
                timeout={300}
                classNames="fade-in">
                <button type="button" className="btn-link go hide" onClick={this.handleCreate(MEDIA_TYPE_LINK)} disabled={this.state.isCreatingLink}>
                  {this.state.isCreatingLink ? <Spinner radius={20} stroke={2} visible={true} /> : <span className="scmty-play" />}
                </button>
              </CSSTransition>
            </div>
            <CSSTransition in={this.props.visible && this.state.linkUrlError !== null} timeout={300} classNames="fade-in">
              <span className="help-block hide">{this.state.linkUrlError}</span>
            </CSSTransition>
            <div className="media-preview">{this.state.links.map((media) => this.renderMedia(media))}</div>
          </div>
        </div>
      </CSSTransition>
    );
  }

  renderPanels() {
    return [this.renderImagePanel(), this.renderVideoPanel(), this.renderDocumentPanel(), this.renderLinkPanel()];
  }

  render() {
    return (
      <div id="post_media" className={classNames('post_media form-group', {hide: !this.props.visible})}>
        {this.renderPanels()}
        {this.renderActions()}
        <ConfirmModal
          open={this.state.confirm !== null}
          title={this.props.intl.formatMessage(messages.modalTitle)}
          body={this.props.intl.formatMessage(messages.modalBody)}
          onConfirm={this.state.confirm}
          onClose={() => this.setState({confirm: null})}
        />
      </div>
    );
  }
}

export default injectIntl(Media);
