import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import ConfirmModal from '../../ConfirmModal';

const messages = defineMessages({
  placeholder: {
    id: 'post.category.placeholder',
    defaultMessage: 'post.category.placeholder'
  },
  delete: {
    id: 'post.category.delete',
    defaultMessage: 'post.category.delete'
  },
  modalTitle: {
    id: 'modal.title',
    defaultMessage: 'modal.title'
  },
  modalBody: {
    id: 'modal.body',
    defaultMessage: 'modal.body'
  }
});

class Category extends Component {
  static defaultProps = {
    visible: false,
    defaultValue: null,
    onChange: null
  };

  static propTypes = {
    visible: PropTypes.bool,
    defaultValue: PropTypes.array,
    onChange: PropTypes.func,

    /* Intl */
    intl: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      categories: this.props.defaultValue,

      /* Suggestions */
      isSuggesting: false,
      value: '',
      suggestions: [],

      /* Confirm */
      confirm: null
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleConfirmClose = this.handleConfirmClose.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleSuggestionsChange = this.handleSuggestionsChange.bind(this);
    this.handleShouldRenderSuggestions = this.handleShouldRenderSuggestions.bind(this);
    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.categories !== this.state.categories && this.props.onChange) {
      this.props.onChange(this.state.categories);
    }
  }

  handleOpen() {
    this.setState({categories: []});
  }

  handleConfirmClose() {
    if (this.state.categories !== null) {
      this.setState({confirm: this.handleClose});
    }
  }

  handleClose() {
    this.setState({categories: null, confirm: null});
  }

  handleRemove(id) {
    return () => this.setState({categories: this.state.categories.filter((category) => category.id !== id)});
  }

  handleSuggestionsChange(event) {
    this.setState({value: event.target.value});
  }

  handleShouldRenderSuggestions() {
    return true;
  }

  handleSuggestionsFetchRequested(obj) {
    if (obj.value.length < parseInt(Q.s('autocompleteMinChars'), 10)) {
      return;
    }
    // Execute request
    const self = this;
    $.ajax({
      type: 'GET',
      url: `${Q.u('categoryList')}`,
      data: {
        search: obj.value,
        limit: 20
      }
    })
      .done((result) => {
        self.setState({suggestions: result.results});
      })
      .always(() => self.setState({isSuggesting: false}));
  }

  handleSuggestionsClearRequested() {
    this.setState({suggestions: [], value: ''});
  }

  handleSuggestionSelected(event, {suggestion}) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({categories: [...this.state.categories, suggestion], value: ''});
  }

  static renderSuggestion(suggestion, {query}) {
    const suggestionText = suggestion.name;
    const matches = match(suggestionText, query);
    const parts = parse(suggestionText, matches);
    return (
      <div className="clearfix suggestion-content">
        <img className="img" width="46" height="46" src={suggestion.image_medium} />
        <div className="text">
          {parts.map((part, index) => {
            return (
              <span className={classNames({highlight: part.highlight})} key={index}>
                {part.text}
              </span>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const suggestionProps = {
      id: 'id_category_target',
      className: classNames({hide: this.state.categories && this.state.categories.length > 0}, 'react-autosuggest__input'),
      placeholder: this.props.intl.formatMessage(messages.placeholder),
      value: this.state.value,
      onChange: this.handleSuggestionsChange
    };
    return (
      <Fragment>
        <div id="post_category" className="post_media form-group" aria-hidden="false">
          <div id="media_category" className="panel clearfix">
            <div
              className={classNames('panel-heading text-left clearfix', {'add-poll': this.state.categories === null})}
              onClick={this.state.categories === null ? this.handleOpen : null}>
              <i className="scmty-hash" />
              <FormattedMessage id="post.category.title" defaultMessage="post.category.title" />
              <button type="button" className="close pull-right" onClick={this.state.categories === null ? this.handleOpen : this.handleConfirmClose}>
                {this.state.categories === null ? <i className="scmty-plus" /> : <i className="scmty-q-remove" />}
              </button>
            </div>
            <CSSTransition in={this.state.categories !== null} timeout={300} classNames="fade" mountOnEnter unmountOnExit appear>
              <div className="panel-body poll-body hide">
                <TransitionGroup
                  id="category_display"
                  className={classNames('clearfix', {hide: this.state.categories && this.state.categories.length === 0})}
                  appear>
                  {this.state.categories &&
                    this.state.categories.map((category) => (
                      <CSSTransition key={`category_${category.name}`} timeout={300} classNames="fade-in">
                        <div className="hide">
                          <span className={`scmty scmty-pad-d`}>
                            {category.name}
                            <button
                              title={this.props.intl.formatMessage(messages.delete)}
                              className="close q-remove"
                              type="button"
                              onClick={this.handleRemove(category.id)}>
                              <i className="scmty-cross" />
                            </button>
                          </span>
                        </div>
                      </CSSTransition>
                    ))}
                </TransitionGroup>
                <CSSTransition
                  in={this.props.visible && this.state.categories && this.state.categories.length === 0}
                  timeout={300}
                  classNames="fade-in"
                  mountOnEnter
                  unmountOnExit>
                  <Autosuggest
                    suggestions={this.state.suggestions}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                    onSuggestionSelected={this.handleSuggestionSelected}
                    highlightFirstSuggestion
                    shouldRenderSuggestions={this.handleShouldRenderSuggestions}
                    focusInputOnSuggestionClick={!Q.s('isMobile')}
                    getSuggestionValue={(suggestion) => suggestion.name}
                    renderSuggestion={Category.renderSuggestion}
                    inputProps={suggestionProps}
                  />
                </CSSTransition>
                <div className="help-block fade hide" aria-hidden="true" />
              </div>
            </CSSTransition>
          </div>
          <ConfirmModal
            open={this.state.confirm !== null}
            title={this.props.intl.formatMessage(messages.modalTitle)}
            body={this.props.intl.formatMessage(messages.modalBody)}
            onConfirm={this.state.confirm}
            onClose={() => this.setState({confirm: null})}
          />
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(Category);
