import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Title from './Title';
import classNames from 'classnames';
import Media from './Media';
import Text from './Text';
import Category from './Category';
import {CSSTransition} from 'react-transition-group';
import Poll from './Poll';
import Spinner from 'react-spinner-material';
import {formatError} from '../../utils/Http';
import {camelCase} from '../../utils/String';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import ReactPlaceholder from 'react-placeholder';
import {RectShape, RoundShape} from 'react-placeholder/lib/placeholders';
import Location from './Location/Location';
import Addressing from './Addressing';

const Placeholder = (
  <div style={{padding: 10}}>
    <RoundShape color={null} style={{width: 30, height: 30, margin: '0 auto'}} className="bg-colorback-loader" />
    <RectShape color={null} style={{margin: '10px auto', height: 30}} className="bg-colorback-loader" />
    <RectShape color={null} style={{margin: '10px auto', height: 30}} className="bg-colorback-loader" />
    <RectShape color={null} style={{margin: '10px auto', height: 30}} className="bg-colorback-loader" />
  </div>
);

const messages = defineMessages({
  close: {
    id: 'post.close',
    defaultMessage: 'post.close'
  }
});

const TYPE_DISCUSSION = 'discussion';
const TYPE_POST = 'post';
const TYPES = [TYPE_POST, TYPE_DISCUSSION];

class Post extends Component {
  static defaultProps = {
    open: false,
    id: null,
    type: null,
    avatar: null,
    cancel: null,
    addressing: []
  };

  static propTypes = {
    /* Other */
    open: PropTypes.bool,
    username: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    cancel: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.oneOf(TYPES),
    addressing: PropTypes.array,

    /* Intl */
    intl: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      open: this.props.open,
      type: this.props.type,
      isLoading: this.props.id !== null,
      isSubmitting: false,

      /* Post fields */
      title: '',
      text: '',
      medias: [],
      categories: null,
      addressing: null,
      location: null,
      poll: null,

      progress: [],

      /* Errors */
      childErrors: {},
      titleError: null,
      mediasError: null,
      textError: null,
      addressingError: null,
      locationError: null,
      pollError: null
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleProgressStart = this.handleProgressStart.bind(this);
    this.handleProgressEnd = this.handleProgressEnd.bind(this);
    this.handleChildError = this.handleChildError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.state.isLoading) {
      const self = this;
      // Load post
      $.ajax({
        type: 'GET',
        url: `${Q.u(this.props.type)}${this.props.id}/`,
        contentType: 'application/json'
      })
        .done((result) => {
          this.setState({
            title: result.title,
            text: result.html,
            medias: result.medias,
            categories: result.categories.length > 0 ? result.categories : null,
            location: result.location,
            poll: result.poll,
            addressing: result.addressing.length > 0 ? result.addressing : null,
            open: true,
            isLoading: false
          });
        })
        .fail((error) => {
          self.setState(formatError(error.responseJSON.errors, {isLoading: false}));
        });
    }
  }

  handleOpen(type) {
    return () => this.setState({open: true, type});
  }

  handleClose() {
    if (this.props.cancel) {
      document.location = this.props.cancel;
    } else {
      this.setState({open: false});
    }
  }

  handleChange(prop) {
    return (value) => this.setState({[prop]: value, [`${camelCase(prop)}Error`]: null});
  }

  handleProgressStart(id) {
    this.setState({progress: [...this.state.progress, id]});
  }

  handleProgressEnd(id) {
    this.setState({progress: this.state.progress.filter((i) => i !== id)});
  }

  handleChildError(child) {
    return (error) => {
      this.setState({childErrors: Object.assign({}, this.state.childErrors, {[child]: error})});
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const self = this;
    this.setState({isSubmitting: true});

    // Compile data
    const data = {
      text: this.state.text
    };
    if (this.state.title) {
      data.title = this.state.title;
    }
    if (this.state.categories) {
      data.categories = this.state.categories.map((tag) => tag.id);
    }
    if (this.state.medias) {
      data.medias = this.state.medias.map((media) => media.id);
    }
    if (this.state.location) {
      data.location = this.state.location;
    }
    if (this.state.poll) {
      data.poll = this.state.poll;
      data.poll.choices = data.poll.choices.map((c) => ({choice: c.choice}));
    }
    if (this.state.addressing) {
      data.addressing = this.state.addressing.map((tag) => tag.id);
    }

    // Execute request
    let url = Q.u(this.state.type);
    let type = 'POST';
    if (this.props.id) {
      url = `${url}${this.props.id}/`;
      type = 'PUT';
    }
    $.ajax({
      type,
      url,
      data: JSON.stringify(data),
      contentType: 'application/json'
    })
      .done((result) => {
        if (result.slug !== '') {
          document.location = `/thread/${result.id}/${result.slug}/`;
        } else {
          document.location = `/thread/${result.id}/`;
        }
      })
      .fail((error) => {
        self.setState(formatError(error.responseJSON.errors, {isSubmitting: false}));
      });
  }

  canSubmit() {
    const canSubmit =
      this.state.progress.length === 0 &&
      (this.state.location === null || (this.state.location.lat && this.state.location.lng)) &&
      Object.values(this.state.childErrors).findIndex((el) => el === true) === -1;
    if (this.state.type === TYPE_DISCUSSION) {
      return this.state.type === TYPE_DISCUSSION && Boolean(this.state.title) && canSubmit;
    }
    return (
      (Boolean(
        this.state.text
          .replace(/(<([^>]+)>)/gi, '')
          .replace(/&nbsp;/gi, '')
          .trim()
      ) ||
        this.state.medias.length > 0 ||
        (this.state.poll !== null && this.state.poll.title.length > 0 && this.state.poll.choices.filter((c) => c.choice.length > 0).length === this.state.poll.choices.length)) &&
      canSubmit
    );
  }

  renderForm() {
    return (
      <div className={classNames({open: this.state.open}, 'clearfix')}>
        <div className="post-content">
          <button
            type="button"
            id="post_close"
            title={this.props.intl.formatMessage(messages.close)}
            className="btn-link cancel ed-btn"
            onClick={this.handleClose}>
            <i className="scmty-cross" />
          </button>
          <div className={classNames({'pull-left': !this.state.open}, 'user-wrap')}>
            <span className="avatar-wrap">
              <img
                className="avatar"
                alt={this.props.username}
                src={this.props.avatar || Q.u('sf') + 'scmty/images/anon.png'}
                width="30"
                height="30"
              />
            </span>
          </div>
          {this.state.open ? (
            <form
              id="post_form"
              name="post"
              method="POST"
              autoComplete="off"
              className={classNames({o: this.state.open})}
              onSubmit={this.handleSubmit}>
              {this.state.type === TYPE_DISCUSSION && (
                <Title
                  onChange={this.handleChange('title')}
                  onError={this.handleChildError('title')}
                  visible={this.state.open}
                  error={this.state.titleError}
                  defaultValue={this.state.title}
                />
              )}
              <Text visible={this.state.open} onChange={this.handleChange('text')} error={this.state.textError} defaultValue={this.state.text} />
              <Media
                visible={this.state.open}
                onChange={this.handleChange('medias')}
                onProgressStart={this.handleProgressStart}
                onProgressEnd={this.handleProgressEnd}
                onError={this.handleChildError('media')}
                error={this.state.mediasError}
                defaultValue={this.state.medias}
              />

              {Q.s('canCreatePostWithCategory') && Q.s('hasCategories') && (
                <Category visible={this.state.open} onChange={this.handleChange('categories')} defaultValue={this.state.categories} />
              )}

              {this.props.addressing && this.props.addressing.length > 0 && (
                <Addressing
                  visible={this.state.open}
                  onChange={this.handleChange('addressing')}
                  error={this.state.addressingError}
                  defaultValue={this.state.addressing}
                  tags={this.props.addressing}
                />
              )}

              {Q.s('canLocatePost') && (
                <Location
                  visible={this.state.open}
                  onChange={this.handleChange('location')}
                  onError={this.handleChildError('location')}
                  error={this.state.locationError}
                  defaultValue={this.state.location}
                />
              )}

              {Q.s('canCreatePoll') && (
                <Poll visible={this.state.open} onChange={this.handleChange('poll')} error={this.state.pollError} defaultValue={this.state.poll} />
              )}

              <CSSTransition in={this.state.open} timeout={300} classNames="fade-in" appear>
                <div id="post_btns" className="form-group hide" aria-hidden="false">
                  <div className="ppublish">
                    <button type="submit" className="btn btn-primary" id="post_submit" disabled={!this.canSubmit() || this.state.isSubmitting}>
                      {this.state.isSubmitting ? (
                        <Spinner radius={15} stroke={2} visible={true} color="#FFF" />
                      ) : (
                        <FormattedMessage id="post.submit" defaultMessage="post.submit" />
                      )}
                    </button>
                  </div>
                </div>
              </CSSTransition>
            </form>
          ) : (
            <div className="post-choice">
              {TYPES.filter((t) => Q.s(`${t}Enabled`)).map((t) => (
                <button key={t} className="btn btn-link" onClick={this.handleOpen(t)}>
                  <FormattedMessage id={`post.type.${t}`} defaultMessage={`post.type.${t}`} />
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    if (!Q.s('auth')) {
      return null;
    }
    return (
      <ReactPlaceholder ready={!this.state.isLoading} customPlaceholder={Placeholder} showLoadingAnimation>
        {this.renderForm()}
      </ReactPlaceholder>
    );
  }
}

export default injectIntl(Post);
