/**
 * Community Tour types
 */
export const INTERESTING_TYPE = 'interesting_layer_complete';
export const NOTIFICATION_TYPE = 'notification_layer_complete';
export const PROFILE_READ_TYPE = 'profile_read_layer_complete';
export const PROFILE_READ_MINE_TYPE = 'profile_read_mine_layer_complete';
export const TOUR_TYPES = {
  [INTERESTING_TYPE]: INTERESTING_TYPE,
  [NOTIFICATION_TYPE]: NOTIFICATION_TYPE,
  [PROFILE_READ_TYPE]: PROFILE_READ_TYPE,
  [PROFILE_READ_MINE_TYPE]: PROFILE_READ_MINE_TYPE
};

/**
 * Community Tour Targets
 */
export const INTERESTING_TARGET = '#tstep_interesting';
export const INTERESTS_TARGET = '.tstep_sidebar_interests';
export const NEW_CONTENTS_TARGET = '.tstep_contents';
export const PEOPLE_TARGET = '#tstep_people';
export const WIDGETS_TARGET = '#tstep_widgets';
export const PLATFORM_ACCESS_TARGET = '.tstep_platform_access';
export const OPEN_TOUR_TARGET = '.tstep_openIntroTour';
export const SUSPEND_NOTIFICATION_TARGET = '.suspend-action:first-child';
export const REQUEST_CONNECTION_TARGET = '#tstep_btnconnection';
export const REQUEST_FOLLOW_TARGET = '#tstep_btnfollow';
export const FIND_PEOPLE_TARGET = '.tstep_find_people';
export const LOYALTY_TARGET = '#tstep_loyalty';
export const SUGGESTED_CATEGORIES_TARGET = '#tstep_suggested_categories';
export const SUGGESTED_USERS_TARGET = '#tstep_suggested_users';
