import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import ConfirmModal from '../../ConfirmModal';
import classNames from 'classnames';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import {formatDate} from '../../../utils/Http';

const messages = defineMessages({
  pollTitlePlaceholder: {
    id: 'post.poll.title.placeholder',
    defaultMessage: 'post.poll.title.placeholder'
  },
  choicePlaceholder: {
    id: 'post.poll.choice.placeholder',
    defaultMessage: 'post.poll.choice.placeholder'
  },
  choicesError: {
    id: 'post.poll.choices.error',
    defaultMessage: 'post.poll.choices.error'
  },
  closePlaceholder: {
    id: 'post.poll.close.placeholder',
    defaultMessage: 'post.poll.close.placeholder'
  },
  modalTitle: {
    id: 'modal.poll.title',
    defaultMessage: 'modal.poll.title'
  },
  modalBody: {
    id: 'modal.poll.body',
    defaultMessage: 'modal.poll.body'
  }
});

const _defaultPoll = {
  title: '',
  multiple_choices: false,
  expiration_at: null,
  choices: [{choice: ''}, {choice: ''}]
};

class Poll extends Component {
  static defaultProps = {
    visible: false,
    defaultValue: null,
    titleMaxLength: 160,
    choiceMaxLength: 100,
    error: null,
    onChange: null
  };

  static propTypes = {
    visible: PropTypes.bool,
    defaultValue: PropTypes.object,
    titleMaxLength: PropTypes.number,
    choiceMaxLength: PropTypes.number,
    error: PropTypes.object,
    onChange: PropTypes.func,

    /* Intl */
    intl: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      poll: this.props.defaultValue ? Object.assign({}, this.props.defaultValue) : null,
      closed: this.props.defaultValue ? this.props.defaultValue.closed : false,

      /* Confirm */
      confirm: null
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleConfirmRemove = this.handleConfirmRemove.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddChoice = this.handleAddChoice.bind(this);
    this.handleDeleteChoice = this.handleDeleteChoice.bind(this);

    this.datepickerRef = React.createRef();
  }

  jqInit() {
    const minExpiration = new Date();
    minExpiration.setDate(minExpiration.getDate() + parseInt(Q.s('pollMinExpirationDays'), 10));
    $(this.datepickerRef.current)
      .on('change', this.handleChange('expiration_at'))
      .parent()
      .datepicker({
        startDate: minExpiration,
        clearBtn: true,
        autoclose: true,
        todayHighlight: true,
        language: Q.s('locale')
      });
  }

  componentDidMount() {
    if (this.props.defaultValue !== null) {
      this.jqInit();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.poll === null && this.state.poll !== null) {
      this.jqInit();
    }
    if (prevState.poll !== this.state.poll && this.props.onChange) {
      this.props.onChange(this.state.poll);
    }
  }

  handleAdd() {
    this.setState({poll: {..._defaultPoll}});
  }

  handleConfirmRemove() {
    if (this.state.poll !== null) {
      this.setState({confirm: this.handleRemove});
    }
  }

  handleRemove() {
    this.setState({poll: null, confirm: null});
  }

  handleChange(field) {
    return (event) => {
      if (field.startsWith('choice')) {
        const idx = parseInt(field.split('_')[1], 10);
        this.setState({
          poll: Object.assign({}, this.state.poll, {
            choices: this.state.poll.choices.map((c, index) => (idx === index ? {choice: event.target.value} : c))
          })
        });
      } else if (field === 'multiple_choices' || field === 'closed') {
        this.setState({poll: Object.assign({}, this.state.poll, {[field]: !this.state.poll[field]})});
      } else if (field === 'expiration_at') {
        this.setState({
          poll: Object.assign({}, this.state.poll, {expiration_at: formatDate($(this.datepickerRef.current).parent().datepicker('getDate'))})
        });
      } else {
        this.setState({poll: Object.assign({}, this.state.poll, {[field]: event.target.value})});
      }
    };
  }

  handleAddChoice() {
    this.setState({
      poll: Object.assign({}, this.state.poll, {
        choices: [...this.state.poll.choices, {choice: ''}]
      })
    });
  }

  handleDeleteChoice(index) {
    return (event) => {
      if (this.state.poll.choices.length === 2) {
        showMessage(event.target, this.props.intl.formatMessage(messages.choicesError), 'error', 'auto left');
      } else {
        this.setState({
          poll: Object.assign({}, this.state.poll, {
            choices: this.state.poll.choices.filter((c, idx) => idx !== index)
          })
        });
      }
    };
  }

  renderPreview(poll) {
    const hasGeneralError = Boolean(this.props.error && this.props.error.error);
    return (
      <div id="post_poll" className="post_media form-group fade in" aria-hidden="false">
        <div className="poll_voting js-poll" data-pollvotescount="1">
          <div className="poll_voting_head">
            <FormattedMessage id="post.poll.preview.title" defaultMessage="post.poll.preview.title" />
          </div>
          <div className="poll_voting_body">
            <CSSTransition in={hasGeneralError} classNames="fade" timeout={300} appear>
              <div className={classNames('hide', {'has-error': hasGeneralError})}>
                <span className="help-block">{hasGeneralError ? this.props.error.error : null}</span>
              </div>
            </CSSTransition>
            <h2>{poll.title}</h2>
            {poll.expiration_at && (
              <div className="poll_voting_info align-center">
                <FormattedMessage
                  id="post.poll.preview.info"
                  defaultMessage="post.poll.preview.info"
                  values={{
                    span: (chunks) => <span className="js-date-iso-to-local">{chunks}</span>,
                    date: this.props.intl.formatDate(Date.parse(poll.expiration_at))
                  }}
                />
              </div>
            )}
            {poll.choices.map((choice) => {
              const votesPercentage = 100 * (choice.vote_count / poll.vote_count);
              return (
                <div className="choice" key={choice.choice}>
                  <div className="choice_choice">
                    <div style={{display: 'inline-block'}}>{choice.choice}</div>
                  </div>
                  <div className="choice_bar_container align-left pt10">
                    <span className="choice_bar js-choice-bar" style={{width: `${votesPercentage}%`}}>
                      &nbsp;
                    </span>{' '}
                    <span className="choice_percentage js-choice-percentage">{`${votesPercentage}%`}</span>
                  </div>
                </div>
              );
            })}
            <div className="poll_votes_count align-center">
              <i className="scmty-interested-people" />
              &nbsp;
              <span>
                <FormattedMessage id="post.poll.preview.voters" defaultMessage="post.poll.preview.voters" values={{voters: poll.vote_count}} />
              </span>
              <br />
            </div>
          </div>
        </div>
        <div className="poll-more-options">
          <div className="poll-closing">
            {this.state.closed ? (
              <FormattedMessage id="post.poll.preview.closed" defaultMessage="post.poll.preview.closed" />
            ) : (
              <FormattedMessage id="post.poll.preview.unvoted" defaultMessage="post.poll.preview.unvoted" />
            )}
          </div>
          {!this.state.closed && (
            <div className="poll-checkbox">
              <label>
                <input type="checkbox" name="poll_closed" id="id_poll_closed" checked={poll.closed} onChange={this.handleChange('closed')} />
                <FormattedMessage id="post.poll.preview.close.label" defaultMessage="post.poll.preview.close.label" />
              </label>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderEdit(poll) {
    const hasGeneralError = Boolean(this.props.error && this.props.error.error);
    return (
      <Fragment>
        <div id="post_poll" className="post_media form-group hide" aria-hidden="false">
          <div id="media_poll" className="panel clearfix">
            <div
              className={classNames('panel-heading text-left clearfix', {'add-poll': this.state.poll === null})}
              onClick={this.state.poll === null ? this.handleAdd : null}>
              <i className="media_poll_icon scmty-check-circle" />
              <FormattedMessage id="post.poll.title" defaultMessage="post.poll.title" />
              <button type="button" className="close pull-right" onClick={this.state.poll === null ? this.handleAdd : this.handleConfirmRemove}>
                {this.state.poll === null ? <i className="media_poll_icon scmty-plus" /> : <i className="media_poll_icon scmty-q-remove" />}
              </button>
            </div>
            <CSSTransition in={this.state.poll !== null} timeout={300} classNames="fade" mountOnEnter unmountOnExit appear>
              <div className="panel-body poll-body hide">
                <CSSTransition in={hasGeneralError} classNames="fade" timeout={300} appear>
                  <div className={classNames('hide', {'has-error': hasGeneralError})}>
                    <span className="help-block">{hasGeneralError ? this.props.error.error : null}</span>
                  </div>
                </CSSTransition>
                <div id="poll_datas" className={classNames('clearfix input-box', {'has-error': this.props.error && this.props.error.titleError})}>
                  <div className="form-group">
                    <input
                      autoComplete="off"
                      type="text"
                      name="poll_title"
                      placeholder={this.props.intl.formatMessage(messages.pollTitlePlaceholder)}
                      className="form-control"
                      maxLength={this.props.titleMaxLength}
                      id="id_poll_title"
                      value={poll.title}
                      onChange={this.handleChange('title')}
                    />
                    <div className="js-poll-title-chars-count poll-chars-count pull-right">{this.props.titleMaxLength - poll.title.length}</div>
                    <CSSTransition in={Boolean(this.props.error && this.props.error.titleError)} classNames="fade" timeout={300}>
                      <span className="help-block hide">
                        {this.props.error && this.props.error.titleError ? this.props.error.titleError.error : null}
                      </span>
                    </CSSTransition>
                  </div>
                  <div className={classNames('form-group', {'has-error': this.props.error && this.props.error.choicesError})}>
                    <input type="hidden" id="poll_choices_ids" name="poll_choices_ids" defaultValue="1 2" />
                    <div id="poll_datas_sortable" className="clearfix input-box">
                      {poll.choices.map((choice, index) => (
                        <div className="input-group" key={`choice_${index}`}>
                          <span className="input-group-addon scmty-drag_handle" />
                          <input
                            type="text"
                            maxLength={this.props.choiceMaxLength}
                            className="poll_choice form-control"
                            placeholder={this.props.intl.formatMessage(messages.choicePlaceholder)}
                            value={choice.choice}
                            onChange={this.handleChange(`choice_${index}`)}
                          />
                          <span className="input-group-addon scmty-q-trash" onClick={this.handleDeleteChoice(index)} />
                        </div>
                      ))}
                    </div>
                    <CSSTransition in={Boolean(this.props.error && this.props.error.choicesError)} classNames="fade" timeout={300}>
                      <span className="help-block hide">
                        {this.props.error && this.props.error.choicesError ? this.props.error.choicesError.choiceError.error : null}
                      </span>
                    </CSSTransition>
                  </div>
                  <div>
                    <button title="Add a choice" className="btn btn-link poll_add_choice" onClick={this.handleAddChoice} type="button">
                      <span className="scmty-plus-sign" /> <FormattedMessage id="post.poll.choice.add" defaultMessage="post.poll.choice.add" />
                    </button>
                    <hr />
                    <div className="poll-more-options">
                      <div className="poll-checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="poll_multiple_choices"
                            id="id_poll_multiple_choices"
                            checked={poll.multiple_choices}
                            onChange={this.handleChange('multiple_choices')}
                          />
                          &nbsp;
                          <FormattedMessage id="post.poll.choice.multiple" defaultMessage="post.poll.choice.multiple" />
                        </label>
                      </div>
                    </div>
                    <div className={classNames('form-group', {'has-error': this.props.error && this.props.error.expirationAtError})}>
                      <div className="input-group date poll_closing_date">
                        <input
                          type="text"
                          name="poll_expiration_at"
                          placeholder={this.props.intl.formatMessage(messages.closePlaceholder)}
                          className="form-control"
                          id="id_poll_expiration_at"
                          ref={this.datepickerRef}
                          defaultValue={poll.expiration_at ? this.props.intl.formatDate(Date.parse(poll.expiration_at)) : null}
                        />
                        <span className="input-group-addon scmty-q-clock" />
                      </div>
                      <CSSTransition in={Boolean(this.props.error && this.props.error.expirationAtError)} classNames="fade" timeout={300}>
                        <span className="help-block hide">
                          {this.props.error && this.props.error.expirationAtError ? this.props.error.expirationAtError.error : null}
                        </span>
                      </CSSTransition>
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
          <ConfirmModal
            open={this.state.confirm !== null}
            title={this.props.intl.formatMessage(messages.modalTitle)}
            body={this.props.intl.formatMessage(messages.modalBody)}
            onConfirm={this.state.confirm}
            onClose={() => this.setState({confirm: null})}
          />
        </div>
      </Fragment>
    );
  }

  render() {
    let {poll} = this.state;
    if (poll === null) {
      poll = _defaultPoll;
    }
    poll.vote_count = 0;
    if (poll && poll.choices && poll.choices.length > 0) {
      poll.choices.map((c) => (poll.vote_count += c.vote_count));
    }
    return (
      <CSSTransition in={this.props.visible} timeout={300} classNames="fade-in" appear>
        {poll && poll.vote_count > 0 ? this.renderPreview(poll) : this.renderEdit(poll)}
      </CSSTransition>
    );
  }
}

export default injectIntl(Poll);
