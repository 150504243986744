/* eslint-disable no-useless-escape */
import React from 'react';

export function capitalize(str) {
  let strVal = '';
  str = str.split(' ');
  for (let chr = 0; chr < str.length; chr++) {
    strVal += str[chr].substring(0, 1).toUpperCase() + str[chr].substring(1, str[chr].length);
  }
  return strVal;
}

export function camelCase(str) {
  // Lower cases the string
  return (
    str
      .toLowerCase()
      // Replaces any - or _ characters with a space
      .replace(/[-_]+/g, ' ')
      // Removes any non alphanumeric characters
      .replace(/[^\w\s]/g, '')
      // Uppercases the first character in each group immediately following a space
      // (delimited by spaces)
      .replace(/ (.)/g, ($1) => {
        return $1.toUpperCase();
      })
      // Removes spaces
      .replace(/ /g, '')
  );
}

export function slugify(str) {
  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function ellipsify(text, n) {
  // Ellipsify a text
  if (text.length > n) {
    return text.substring(0, n) + '...';
  }
  return text;
}

// Test if a string is an integer number
export function isInteger(s) {
  return /^\+?[0-9][\d]*$/.test(s);
}

export function highlight(text, search) {
  if (text === search) {
    return <b>{search}</b>;
  }
  const parts = text.split(search);
  return parts.map((val, idx) =>
    val === '' ? (
      <b key={`hl_${idx}`}>{search}</b>
    ) : idx > 0 && parts[idx - 1] !== '' ? (
      <span key={`hl_${idx}`}>
        <b>{search}</b>
        {val}
      </span>
    ) : (
      val
    )
  );
}

// Add thousand separator to a number and return as a string
export function formatAmountWithThousandSeparator(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
