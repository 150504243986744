import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

export default class ConfirmModal extends PureComponent {
  static defaultProps = {
    open: false
  };

  static propTypes = {
    /* Other */
    title: PropTypes.string.isRequired,
    body: PropTypes.string,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {};

    this.ref = React.createRef();
  }

  componentDidMount() {
    const self = this;
    $(this.ref.current).on('hidden.bs.modal', function () {
      self.props.onClose();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      $(this.ref.current).modal(this.props.open ? 'show' : 'hide');
    }
  }

  componentWillUnmount() {
    $(this.ref.current).off('hidden.bs.modal');
  }

  render() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true" ref={this.ref}>
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">{this.props.title}</h3>
              {this.props.body && <p className="modal-body">{this.props.body}</p>}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={this.props.onConfirm}>
                <FormattedMessage id="modal.confirm" defaultMessage="modal.confirm" />
              </button>
              &nbsp;
              <button type="button" data-dismiss="modal" className="btn btn-primary" onClick={this.props.onClose}>
                <FormattedMessage id="modal.cancel" defaultMessage="modal.cancel" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
