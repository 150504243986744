import {camelCase} from './String';

export const defaultError = (error) => {
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
};

export const formatError = (errors, updateState) => {
  if (errors && typeof errors === 'object') {
    if (Array.isArray(errors)) {
      for (let i = 0; i < errors.length; i++) {
        const err = errors[i];
        if (err.message) {
          updateState.error = err.message;
        } else {
          const _e = formatError(err.messages, {});
          if (`${camelCase(err.field)}Error` in _e) {
            updateState = {...updateState, ..._e};
          } else {
            updateState[`${camelCase(err.field)}Error`] = _e;
          }
        }
      }
    } else {
      updateState.error = errors.errors;
    }
  } else {
    updateState.error = 'error';
    defaultError(errors);
  }
  return updateState;
};

export const formatDate = (date) => {
  if (!date) {
    return date;
  }
  let d = new Date(Date.parse(date)),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};
