import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {
  FIND_PEOPLE_TARGET,
  INTERESTING_TARGET,
  INTERESTING_TYPE,
  INTERESTS_TARGET,
  NEW_CONTENTS_TARGET,
  NOTIFICATION_TYPE,
  OPEN_TOUR_TARGET,
  PEOPLE_TARGET,
  PLATFORM_ACCESS_TARGET,
  PROFILE_READ_MINE_TYPE,
  PROFILE_READ_TYPE,
  REQUEST_CONNECTION_TARGET,
  REQUEST_FOLLOW_TARGET,
  SUSPEND_NOTIFICATION_TARGET,
  WIDGETS_TARGET
} from '../../constants/CommunityTour';

/**
 * Base component
 * Used for all steps
 */
const BaseStep = function (props) {
  return (
    <div>
      <h4>
        <b>{props.title && props.title}</b>
      </h4>
      <br />
      {props.content && props.content}
    </div>
  );
};

BaseStep.propTypes = {
  title: PropTypes.node,
  content: PropTypes.node
};

// HomePage

/**
 * Welcome step component
 * Pass props if you want
 */
class WelcomeStep extends React.Component {
  render() {
    return (
      <BaseStep
        title={<FormattedMessage id="communityTour.welcomeStepTitle" defaultMessage="communityTour.welcomeStepTitle" />}
        content={<FormattedMessage id="communityTour.welcomeStepContent" defaultMessage="communityTour.welcomeStepContent" />}
      />
    );
  }
}

/**
 * Interesting step component
 */
class InterestingStep extends React.Component {
  render() {
    return (
      <BaseStep
        title={<FormattedMessage id="communityTour.interestingStepTitle" defaultMessage="communityTour.interestingStepTitle" />}
        content={<FormattedMessage id="communityTour.interestingStepContent" defaultMessage="communityTour.interestingStepContent" />}
      />
    );
  }
}

/**
 * Interests step component
 */
class InterestsStep extends React.Component {
  render() {
    return (
      <BaseStep
        title={<FormattedMessage id="communityTour.interestsStepTitle" defaultMessage="communityTour.interestsStepTitle" />}
        content={<FormattedMessage id="communityTour.interestsStepContent" defaultMessage="communityTour.interestsStepContent" />}
      />
    );
  }
}

/**
 * NewPost step component
 */
class ContentsStep extends React.Component {
  render() {
    return (
      <BaseStep
        title={<FormattedMessage id="communityTour.contentsStepTitle" defaultMessage="communityTour.contentsStepTitle" />}
        content={<FormattedMessage id="communityTour.contentsStepContent" defaultMessage="communityTour.contentsStepContent" />}
      />
    );
  }
}

/**
 * People step component
 */
class PeopleStep extends React.Component {
  render() {
    return (
      <BaseStep
        title={<FormattedMessage id="communityTour.peopleStepTitle" defaultMessage="communityTour.peopleStepTitle" />}
        content={<FormattedMessage id="communityTour.peopleStepContent" defaultMessage="communityTour.peopleStepContent" />}
      />
    );
  }
}

/**
 * Widgets step component
 */
class WidgetsStep extends React.Component {
  render() {
    return (
      <BaseStep
        title={<FormattedMessage id="communityTour.widgetsStepTitle" defaultMessage="communityTour.widgetsStepTitle" />}
        content={<FormattedMessage id="communityTour.widgetsStepContent" defaultMessage="communityTour.widgetsStepContent" />}
      />
    );
  }
}

/**
 * Platform Access step component
 */
class PlatformAccessStep extends React.Component {
  render() {
    return (
      <BaseStep
        title={<FormattedMessage id="communityTour.platformAccessStepTitle" defaultMessage="communityTour.platformAccessStepTitle" />}
        content={<FormattedMessage id="communityTour.platformAccessStepContent" defaultMessage="communityTour.platformAccessStepContent" />}
      />
    );
  }
}

/**
 * OpenIntroTour step component
 */
class OpenIntroTourStep extends React.Component {
  render() {
    return (
      <BaseStep
        title={<FormattedMessage id="communityTour.openIntroTourStepTitle" defaultMessage="communityTour.openIntroTourStepTitle" />}
        content={<FormattedMessage id="communityTour.openIntroTourStepContent" defaultMessage="communityTour.openIntroTourStepContent" />}
      />
    );
  }
}

// Notifications/Interactions Page

/**
 * SuspendNotifications step component
 */
class SuspendNotificationsStep extends React.Component {
  render() {
    return (
      <BaseStep
        title={<FormattedMessage id="communityTour.suspendNotificationsStepTitle" defaultMessage="communityTour.suspendNotificationsStepTitle" />}
        content={
          <FormattedMessage id="communityTour.suspendNotificationsStepContent" defaultMessage="communityTour.suspendNotificationsStepContent" />
        }
      />
    );
  }
}

// Profile(Read) Page

/**
 * Connect step component
 */
class ConnectStep extends React.Component {
  render() {
    return (
      <BaseStep
        title={<FormattedMessage id="communityTour.connectStepTitle" defaultMessage="communityTour.connectStepTitle" />}
        content={<FormattedMessage id="communityTour.connectStepContent" defaultMessage="communityTour.connectStepContent" />}
      />
    );
  }
}

/**
 * Follow step component
 */
class FollowStep extends React.Component {
  render() {
    return (
      <BaseStep
        title={<FormattedMessage id="communityTour.followStepTitle" defaultMessage="communityTour.followStepTitle" />}
        content={<FormattedMessage id="communityTour.followStepContent" defaultMessage="communityTour.followStepContent" />}
      />
    );
  }
}

// MyProfile Page

/**
 * Connect step component
 */
class FindPeopleStep extends React.Component {
  render() {
    return (
      <BaseStep
        title={<FormattedMessage id="communityTour.findPeopleStepTitle" defaultMessage="communityTour.findPeopleStepTitle" />}
        content={<FormattedMessage id="communityTour.findPeopleContent" defaultMessage="communityTour.findPeopleStepContent" />}
      />
    );
  }
}

/**
 * Export steps
 * @param tutorial_type
 */
export const getSteps = function (props) {
  const {tutorial_type, follow_enabled} = props;
  if (tutorial_type === INTERESTING_TYPE) {
    return [
      {
        placement: 'center',
        target: 'body',
        content: <WelcomeStep />,
        disableBeacon: true,
        spotlightPadding: 0
      },
      {
        target: INTERESTING_TARGET,
        content: <InterestingStep />,
        placement: 'bottom',
        disableBeacon: true,
        spotlightPadding: 0
      },
      {
        target: INTERESTS_TARGET,
        content: <InterestsStep />,
        placement: 'right',
        disableBeacon: true,
        spotlightPadding: 0
      },
      {
        target: NEW_CONTENTS_TARGET,
        content: <ContentsStep />,
        placement: 'bottom',
        disableBeacon: true,
        spotlightPadding: 0
      },
      {
        target: PEOPLE_TARGET,
        content: <PeopleStep />,
        placement: 'bottom',
        disableBeacon: true,
        spotlightPadding: 0
      },
      {
        target: WIDGETS_TARGET,
        content: <WidgetsStep />,
        placement: 'left',
        disableBeacon: true,
        spotlightPadding: 0
      },
      {
        target: PLATFORM_ACCESS_TARGET,
        content: <PlatformAccessStep />,
        placement: 'left',
        disableBeacon: true,
        spotlightPadding: 0
      },
      {
        target: OPEN_TOUR_TARGET,
        content: <OpenIntroTourStep />,
        placement: 'left',
        disableBeacon: true,
        spotlightPadding: 0
      }
    ];
  } else if (tutorial_type === NOTIFICATION_TYPE) {
    return [
      {
        target: SUSPEND_NOTIFICATION_TARGET,
        content: <SuspendNotificationsStep />,
        placement: 'bottom',
        disableBeacon: true,
        spotlightPadding: 0
      }
    ];
  } else if (tutorial_type === PROFILE_READ_TYPE) {
    return [
      ...(!follow_enabled
        ? [
            {
              target: REQUEST_CONNECTION_TARGET,
              content: <ConnectStep />,
              placement: 'bottom',
              disableBeacon: true,
              spotlightPadding: 0
            }
          ]
        : []),
      ...(follow_enabled
        ? [
            {
              target: REQUEST_FOLLOW_TARGET,
              content: <FollowStep />,
              placement: 'bottom',
              disableBeacon: true,
              spotlightPadding: 0
            }
          ]
        : [])
    ];
  } else if (tutorial_type === PROFILE_READ_MINE_TYPE) {
    return [
      {
        target: FIND_PEOPLE_TARGET,
        content: <FindPeopleStep />,
        placement: 'bottom',
        disableBeacon: true,
        spotlightPadding: 0
      }
    ];
  }
  return [];
};
