import React, {PureComponent} from 'react';
import ReactJoyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride';
import Tooltip from './Tooltip';
import PropTypes from 'prop-types';
import {getSteps} from './steps';
import {enableScroll, disableScroll} from '../../utils/Dom';
import {
  INTERESTING_TYPE,
  INTERESTS_TARGET,
  LOYALTY_TARGET,
  OPEN_TOUR_TARGET,
  SUGGESTED_CATEGORIES_TARGET,
  SUGGESTED_USERS_TARGET,
  WIDGETS_TARGET
} from '../../constants/CommunityTour';

/**
 * Joyride styles
 */
const customStyles = {
  options: {
    primaryColor: '#3d9999',
    zIndex: 10000
  },
  spotlight: {
    height: 30
  }
};

/**
 * Joyride floater props
 */
const floaterProps = {
  disableAnimation: true,
  style: {
    floater: {
      transition: 'opacity 0.5s'
    },
    floaterWithAnimation: {
      opacity: 1,
      transition: 'opacity 0.5s',
      visibility: 'visible'
    }
  }
};

/**
 * Name handlers to use in jQuery off() callback to remove only this callback
 */
const _onOverlayHandler = (e) => {
  e.stopPropagation();
};
const _onTooltipHandler = (e) => {
  if (!$(e.target).hasClass('btn') && !$(e.target).is('a') && !$(e.target).is('i')) {
    e.stopPropagation();
  }
};
const _onBsDropdownMenu = (e) => {
  e.preventDefault();
};

/**
 * Break point drawer layout
 */
const breakPointLayout = 1252;

/**
 * Main Class Community Tour
 */
export default class CommunityTour extends PureComponent {
  static defaultProps = {
    is_visible: false,
    src: null,
    tutorial_type: null
  };

  static propTypes = {
    is_visible: PropTypes.bool.isRequired,
    src: PropTypes.string.isRequired,
    tutorial_type: PropTypes.string.isRequired,
    mark_seen_url: PropTypes.string,
    follow_enabled: PropTypes.bool.isRequired,
    ads_enabled: PropTypes.bool.isRequired,
    custom_adv_enabled: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      run: false,
      isLoading: false,
      initialDrawerOpen: false,
      steps: getSteps(this.props),
      stepIndex: 0,
      streamLoaded: false
    };
    this.width = window.innerWidth;
    this.loadingStep = false;

    // Fetch/Manage Community Tour user state
    this.checkCommunityTourStatus = this.checkCommunityTourStatus.bind(this);
    this.setUserCommunityTourState = this.setUserCommunityTourState.bind(this);

    // Control Tour hooks
    this.startTour = this.startTour.bind(this);
    this.stopTour = this.stopTour.bind(this);
    this.goToNextStep = this.goToNextStep.bind(this);
    this.setStepIndex = this.setStepIndex.bind(this);
    this.close = this.close.bind(this);
    this.handleJoyrideCallback = this.handleJoyrideCallback.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleExit = this.handleExit.bind(this);
    this.bindOpenTourEvent = this.bindOpenTourEvent.bind(this);

    // Control dynamic event UI
    this.isMinWindow = this.isMinWindow.bind(this);
    this.scrollToTargetByIndex = this.scrollToTargetByIndex.bind(this);
    this.isDrawerOpen = this.isDrawerOpen.bind(this);
    this.setDrawer = this.setDrawer.bind(this);
    this._bindClickHandler = this._bindClickHandler.bind(this);
    this.setStateMenuAvatar = this.setStateMenuAvatar.bind(this);
    this.openUserMenu = this.openUserMenu.bind(this);
    this.closeUserMenu = this.closeUserMenu.bind(this);
    this.showFakeInterests = this.showFakeInterests.bind(this);
    this.showFakeRightSidebar = this.showFakeRightSidebar.bind(this);
    this.setAdvertisingTopBar = this.setAdvertisingTopBar.bind(this);
    this.setAdvertisingRightSidebar = this.setAdvertisingRightSidebar.bind(this);

    // React on resize window
    this.updateTourOnResize = this.updateTourOnResize.bind(this);
    this.updateTourBasedOnKeyPress = this.updateTourBasedOnKeyPress.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateTourOnResize);
    window.addEventListener('keyup', this.updateTourBasedOnKeyPress);
    this.bindOpenTourEvent();
    this.checkCommunityTourStatus();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateTourOnResize);
    window.removeEventListener('keyup', this.updateTourBasedOnKeyPress);
  }

  bindOpenTourEvent() {
    const self = this;
    $('body').bind('startCommunityTour', () => {
      self.setState({stepIndex: 0}, () => {
        self.startTour();
      });
    });
    $('#stream .scroller-content').bind('scrollercontentloaded', () => {
      self.setState({streamLoaded: true}, () => {
        if (!self.state.run && this.props.is_visible) {
          self.startTour();
        }
      });
    });
  }

  /**
   * Check if tour needs to start or not loading status from backend
   */
  checkCommunityTourStatus() {
    // Fetch Community Tour state from backend
    if (this.props.is_visible && this.props.tutorial_type !== INTERESTING_TYPE && !this.state.run) {
      this.setState({isLoading: false}, () => {
        this.startTour();
      });
    }
  }

  /**
   * Set Community Tour state from backend
   */
  setUserCommunityTourState() {
    this.stopTour(() => {
      if (this.props.mark_seen_url && this.props.is_visible) {
        $.ajax({
          url: this.props.mark_seen_url,
          success: function () {},
          error: function () {}
        });
      }
    });
  }

  /**
   * Start Community Tour
   */
  startTour() {
    // Start Community Tour
    const {tutorial_type} = this.props;
    if ((tutorial_type === INTERESTING_TYPE && this.state.streamLoaded) || tutorial_type !== INTERESTING_TYPE) {
      if (this.isMinWindow()) {
        this.setState({run: true});
        this._bindClickHandler(true);
        // Remove advertising TopBar
        this.setAdvertisingTopBar(false);
        // Disable scroll
        disableScroll();
      }
    }
  }

  /**
   * Stop Community Tour
   */
  stopTour(callback) {
    // Close tour
    enableScroll();
    this._bindClickHandler(false);
    this.setState({run: false}, () => {
      return callback ? callback() : null;
    });
  }

  /**
   * Manage KeyPress (catch Arrow Right/Left, Escape)
   * Joyride doesn't attach keyboard listener to perform Next/Prev with arrow keys
   * @param event
   */
  updateTourBasedOnKeyPress(event) {
    const keyName = event.key;
    const {run, stepIndex, steps} = this.state;
    if (run && !this.loadingStep) {
      const size = steps.length;
      if (keyName === 'ArrowRight') {
        $('.bt-ct-next').trigger('click');
      } else if (keyName === 'ArrowLeft' && stepIndex > 0) {
        // Prev step
        $('.bt-ct-previous').trigger('click');
      } else if (keyName === 'Escape') {
        // Exit tour on Escape
        this.close(stepIndex, size);
      }
    }
  }

  /**
   * Disable click on overlay and in tooltip content
   * Avoid user menù closed with a click event on overlay (and in tooltip) when tour is running
   */
  _bindClickHandler(on) {
    if (on) {
      $('body').on('click', '.react-joyride__overlay', _onOverlayHandler);
      $('body').on('click', '.__floater', _onTooltipHandler);
      $('.dropdown').on('hide.bs.dropdown', _onBsDropdownMenu);
    } else {
      $('body').off('click', '.react-joyride__overlay', _onOverlayHandler);
      $('body').off('click', '.__floater', _onTooltipHandler);
      $('.dropdown').off('hide.bs.dropdown', _onBsDropdownMenu);
    }
  }

  /**
   * Return true if interest drawer is open
   */
  isDrawerOpen() {
    return Q.InterestsSidePanel.isOpen();
  }

  /**
   * Open Left drawer, used in step 1 of tour
   */
  setDrawer(open) {
    const _$sidebar_interests = $(INTERESTS_TARGET);
    if (this.isDrawerOpen()) {
      if (open) {
        _$sidebar_interests.addClass('pt-open');
      } else {
        Q.InterestsSidePanel.toggle();
        _$sidebar_interests.removeClass('pt-open');
      }
    } else {
      if (!open) {
        _$sidebar_interests.addClass('pt-open');
      } else {
        Q.InterestsSidePanel.toggle();
        _$sidebar_interests.removeClass('pt-open');
      }
    }
    return open;
  }

  /**
   * Perform click on avatar menù
   */
  openUserMenu() {
    const _$userMenuDropdown = $('.navbar .tm.dropdown');
    if (!_$userMenuDropdown.hasClass('open')) {
      _$userMenuDropdown.addClass('open');
    }
  }

  /**
   * Close menù avatar
   */
  closeUserMenu() {
    const _$userMenuDropdown = $('.navbar .tm.dropdown');
    if (_$userMenuDropdown.hasClass('open')) {
      _$userMenuDropdown.removeClass('open');
    }
  }

  /**
   * Set advertising state of TopBar
   */
  setAdvertisingTopBar(show) {
    if (show) {
      $('div[data-position="BELOW_TOPBAR"]').show();
    } else {
      $('div[data-position="BELOW_TOPBAR"]').hide();
    }
  }

  /**
   * Set advertising state of Widget sidebar
   */
  setAdvertisingRightSidebar(show) {
    if (show) {
      $('div[data-position="TOOLS_COLUMN"]').show();
    } else {
      $('div[data-position="TOOLS_COLUMN"]').hide();
    }
  }

  /**
   * Show/Hide menu avatar
   * Used to show the menù for the last step of tour
   * @param showPopup
   */
  setStateMenuAvatar(showPopup) {
    const menuAppbar = document.getElementById('menu-appbar');
    if (menuAppbar) {
      menuAppbar.style.opacity = showPopup ? '1' : '0';
    }
  }

  /**
   * Show/Hide right fake sidebar
   */
  showFakeRightSidebar(show) {
    const _$tStepWidgets = $(WIDGETS_TARGET);
    // Real tools
    const _$loyalty = $('#box_loyalty_points_collection');
    const _$suggestInterest = $('#suggest_categories');
    const _$suggestUser = $('#suggested_users');
    // Set the correct layout with fake object if need
    if (show) {
      _$tStepWidgets.find('.bx').hide();
      if (!_$loyalty.length) {
        // Show fake loyalty box
        _$tStepWidgets.find(LOYALTY_TARGET).show().removeClass('hide');
      } else {
        _$loyalty.show();
      }
      if (!_$suggestInterest.length) {
        // Show fake suggested interests box
        _$tStepWidgets.find(SUGGESTED_CATEGORIES_TARGET).show().removeClass('hide');
      } else {
        _$suggestInterest.show();
      }
      if (!_$suggestUser.length) {
        // Show fake suggested users box
        _$tStepWidgets.find(SUGGESTED_USERS_TARGET).show().removeClass('hide');
      } else {
        _$suggestUser.show();
      }
      this.setAdvertisingRightSidebar(false);
    } else {
      // Hide all fake box
      _$tStepWidgets.find('.bx.widgets').hide().addClass('hide');
      _$tStepWidgets.find('.bx').show();
      this.setAdvertisingRightSidebar(true);
    }
  }

  /**
   * Show/Hide fake interests in left sidebar only if no categories
   */
  showFakeInterests(show) {
    setTimeout(() => {
      if ($(`${INTERESTS_TARGET} .tstep-r-content .js-interest-menu`).length <= 0) {
        if (show) {
          $(INTERESTS_TARGET).addClass('pt-open');
        } else {
          $(INTERESTS_TARGET).removeClass('pt-open');
        }
      }
    });
  }

  /**
   * Close tour and go to last step if necessary
   */
  close(index, size) {
    this.loadingStep = false;
    if (index < size - 1) {
      this.handleExit(() => {
        this.openUserMenu();
        setTimeout(() => {
          this.setState({stepIndex: size - 1}, () => {
            this.startTour();
          });
        }, 100);
      });
    } else if (index === size - 1) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.handleExit(null, true);
    }
  }

  /**
   * Scroll to target by index
   * @param index
   */
  scrollToTargetByIndex(index) {
    const target = this.state.steps[index].target;
    if (target === WIDGETS_TARGET) {
      $('html, body').animate({scrollTop: 0}, 'slow');
    } else {
      const elem = document.querySelector(target);
      if (elem) {
        elem.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
      }
    }
  }

  /**
   * Set current index step
   * @param nextIndex
   */
  setStepIndex(nextIndex, delay) {
    if (delay) {
      setTimeout(() => {
        this.scrollToTargetByIndex(nextIndex);
        this.setState({stepIndex: nextIndex}, () => {
          this.loadingStep = false;
        });
      }, delay);
    } else {
      this.scrollToTargetByIndex(nextIndex);
      this.setState({stepIndex: nextIndex}, () => {
        this.loadingStep = false;
      });
    }
  }

  /**
   * Go to the next step based on action type (NEXT, PREV)
   * @param currentIndex
   * @param size
   * @param action
   */
  goToNextStep(currentIndex, size, action) {
    const {tutorial_type} = this.props;
    const {initialDrawerOpen} = this.state;
    const nextIndex = currentIndex + (action === ACTIONS.PREV ? -1 : 1);
    this.loadingStep = true;

    if (nextIndex < 0 || nextIndex >= size) {
      // Close tour because nextIndex is out of steps range
      if (tutorial_type === INTERESTING_TYPE) {
        if (this.isDrawerOpen() !== initialDrawerOpen) {
          this.setDrawer(initialDrawerOpen);
        }
        this.showFakeRightSidebar(false);
        this.showFakeInterests(false);
      }
      this.close(currentIndex, size);
    } else if (nextIndex === 2 && tutorial_type === INTERESTING_TYPE) {
      // Step: interests sidebar
      this.showFakeInterests(true);
      if (this.isDrawerOpen()) {
        this.setStepIndex(nextIndex);
      } else {
        if (nextIndex < size) {
          this.setDrawer(true);
        }
        // Wait 450ms to open the drawer and the go to next step
        this.setStepIndex(nextIndex, 350);
      }
    } else if (nextIndex === 5 && tutorial_type === INTERESTING_TYPE) {
      // Step: Widgets sidebar step
      this.closeUserMenu();
      this.showFakeRightSidebar(true);
      this.setStepIndex(nextIndex);
    } else if (nextIndex === size - 1 && action === ACTIONS.NEXT && tutorial_type === INTERESTING_TYPE) {
      // Last step
      this.openUserMenu();
      this.setStepIndex(nextIndex);
    } else {
      // Prev/Next step
      if (tutorial_type === INTERESTING_TYPE) {
        if (nextIndex > 0 && window.innerWidth < breakPointLayout) {
          this.setDrawer(false);
        }
        this.closeUserMenu();
        this.showFakeRightSidebar(false);
        this.showFakeInterests(false);
      }
      this.setStepIndex(nextIndex);
    }
  }

  /**
   * Manage Joyride events callback
   * @param data
   */
  handleJoyrideCallback(data) {
    const {action, index, status, type, size} = data;
    const {run} = this.state;
    if (!this.loadingStep) {
      if ([EVENTS.TOUR_START].includes(type) || ([STATUS.RUNNING].includes(status) && data.action === 'start' && data.lifecycle === 'init')) {
        this.handleStart();
      } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
        if (data.action === 'close' /* || (data.action === 'next' && data.index <= data.size - 2) */) {
          // Workaround to go to last step
          this.close(index, size);
        } else {
          this.goToNextStep(index, size, action);
        }
      } else if ([STATUS.FINISHED].includes(status) || (type === EVENTS.TOUR_END && run)) {
        this.handleExit(null, true);
      }

      // Logging event - uncomment this lines to enable event debug
      /* console.groupCollapsed(type);
      console.log(data); //eslint-disable-line no-console
      console.groupEnd(); */
    }
  }

  /**
   * Perform action start
   */
  handleStart() {
    this.setState({initialDrawerOpen: this.isDrawerOpen()});
    this.scrollToTargetByIndex(0);
  }

  /**
   * Handle exit tour
   */
  handleExit(callback, closeAdv) {
    this.closeUserMenu();
    this.stopTour(callback);
    this.setUserCommunityTourState();
    if (closeAdv) {
      this.setAdvertisingTopBar(true);
    }
  }

  /**
   * Community Tour start if window width and height can contains the tooltip
   */
  isMinWindow() {
    return window.innerWidth >= 640 && window.innerHeight >= 550;
  }

  /**
   * Update tour on resize window
   */
  updateTourOnResize() {
    // Update tour on resize if necessary
    if (!this.isMinWindow()) {
      this.stopTour();
      return;
    }
    // if the window is resized and the drawer closes -> re-open drawer
    if (this.state.stepIndex === 2 && window.innerWidth <= breakPointLayout && this.width > breakPointLayout && window.innerWidth < this.width) {
      this.showFakeInterests(true);
      this.setDrawer(true);
    }
    this.width = window.innerWidth;
  }

  filterSteps(steps) {
    return steps.filter(function (obj) {
      // exclude element not in page or free dynamic element
      if (obj.target !== OPEN_TOUR_TARGET) {
        return document.querySelector(obj.target) !== null;
      }
      return true;
    });
  }

  render() {
    const {run, steps, stepIndex} = this.state;
    return (
      <div>
        <ReactJoyride
          styles={customStyles}
          floaterProps={floaterProps}
          tooltipComponent={Tooltip}
          run={run}
          disableScrolling={true}
          disableScrollParentFix={true}
          scrollOffset={90}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          steps={steps}
          stepIndex={stepIndex}
          debug={false}
          continuous={true}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          callback={this.handleJoyrideCallback}
        />
      </div>
    );
  }
}
