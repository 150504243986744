import React, {PureComponent} from 'react';

export default class Editor extends PureComponent {
  state = {};

  static defaultProps = {};

  render() {
    return <div className="Editor">Editor</div>;
  }
}
