import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import ConfirmModal from '../../ConfirmModal';

const messages = defineMessages({
  emptyPlaceholder: {
    id: 'post.addressing.placeholder.empty',
    defaultMessage: 'post.addressing.placeholder.empty'
  },
  placeholder: {
    id: 'post.addressing.placeholder',
    defaultMessage: 'post.addressing.placeholder'
  },
  delete: {
    id: 'post.addressing.delete',
    defaultMessage: 'post.addressing.delete'
  },
  modalTitle: {
    id: 'modal.title',
    defaultMessage: 'modal.title'
  },
  modalBody: {
    id: 'modal.body',
    defaultMessage: 'modal.body'
  }
});

const getSuggestions = (tags, value, selected) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  // Filter suggestions to removed already inserted data
  const suggestions = tags.filter((tag) => selected.findIndex((t) => t.name === tag.name) === -1);

  return inputLength === 0 ? suggestions : suggestions.filter((tag) => tag.name.toLowerCase().slice(0, inputLength) === inputValue);
};

class Addressing extends Component {
  static defaultProps = {
    visible: false,
    defaultValue: [],
    tags: null,
    onChange: null
  };

  static propTypes = {
    visible: PropTypes.bool,
    defaultValue: PropTypes.array,
    tags: PropTypes.array,
    onChange: PropTypes.func,

    /* Intl */
    intl: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      tags: this.props.defaultValue,

      /* Suggestions */
      value: '',
      suggestions: [],

      /* Confirm */
      confirm: null
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleConfirmClose = this.handleConfirmClose.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.storeAutowhateverRef = this.storeAutowhateverRef.bind(this);
    this.handleSuggestionsChange = this.handleSuggestionsChange.bind(this);
    this.handleSuggestionsKeyDown = this.handleSuggestionsKeyDown.bind(this);
    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tags !== this.state.tags && this.props.onChange) {
      this.props.onChange(this.state.tags);
    }
  }

  storeAutowhateverRef = (autowhatever) => {
    if (autowhatever !== null) {
      this.autosuggestions = autowhatever;
    }
  };

  handleOpen() {
    this.setState({tags: []});
  }

  handleConfirmClose() {
    if (this.state.tags !== null) {
      this.setState({confirm: this.handleClose});
    }
  }

  handleClose() {
    this.setState({tags: null, confirm: null});
  }

  handleRemove(name) {
    return () => this.setState({tags: this.state.tags.filter((tag) => tag.name !== name)});
  }

  handleSuggestionsChange(event) {
    this.setState({value: event.target.value});
  }

  handleSuggestionsKeyDown(event) {
    if (event.keyCode === 8) {
      // Backspace remove last tag if exist
      if (
        this.state.value === '' &&
        this.state.tags.length > 0 &&
        (this.state.tags[this.state.tags.length - 1].active === undefined || this.state.tags[this.state.tags.length - 1].active === true)
      ) {
        const tags = [...this.state.tags];
        tags.splice(-1, 1);
        const suggestions = getSuggestions(this.props.tags, '', tags);
        this.setState({tags, suggestions}, function () {
          this.autosuggestions.revealSuggestions();
        });
      }
    } else if (event.keyCode === 13) {
      // Prevent enter keycode because question title cannot contain break spaces
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  handleSuggestionsFetchRequested({value}) {
    this.setState({suggestions: getSuggestions(this.props.tags, value, this.state.tags)});
  }

  handleSuggestionsClearRequested() {}

  handleSuggestionSelected(event, {suggestion}) {
    event.preventDefault();
    event.stopPropagation();
    const tags = [...this.state.tags, suggestion];
    const suggestions = getSuggestions(this.props.tags, '', tags);
    this.setState({tags: tags, value: ''}, function () {
      this.setState({suggestions: suggestions, value: ''}, function () {
        this.autosuggestions.revealSuggestions();
      });
    });
  }

  static renderSuggestion(suggestion, {query}) {
    const suggestionText = suggestion.name;
    const matches = match(suggestionText, query);
    const parts = parse(suggestionText, matches);
    return (
      <div className="chip" style={{backgroundColor: suggestion.color}}>
        {parts.map((part, index) => {
          return (
            <span className={classNames({highlight: part.highlight})} key={index}>
              {part.text}
            </span>
          );
        })}
      </div>
    );
  }

  static shouldRenderSuggestions() {
    return true;
  }

  static renderSuggestionsContainer({containerProps, children}) {
    return (
      <div {...containerProps}>
        <div className="addressing-suggestion-title">
          <FormattedMessage id="post.addressing.suggestion.title" defaultMessage="post.addressing.suggestion.title" />
        </div>
        {children}
      </div>
    );
  }

  render() {
    const suggestionProps = {
      id: 'id_addressing_target',
      placeholder:
        this.state.tags && this.state.tags.length === 0
          ? this.props.intl.formatMessage(messages.emptyPlaceholder)
          : this.props.intl.formatMessage(messages.placeholder),
      value: this.state.value,
      onChange: this.handleSuggestionsChange,
      onKeyDown: this.handleSuggestionsKeyDown
    };
    return (
      <CSSTransition in={this.props.visible} timeout={300} classNames="fade-in" appear>
        <div id="post_addressing" className="post_media form-group hide" aria-hidden="false">
          <div id="media_addressing" className="panel clearfix" aria-hidden="false">
            <div
              className={classNames('panel-heading text-left clearfix', {'add-tags': this.state.tags === null})}
              onClick={this.state.tags === null ? this.handleOpen : null}>
              <i className="scmty-interesting" />
              <FormattedMessage id="post.addressing.title" defaultMessage="post.addressing.title" />
              <button type="button" className="close pull-right" onClick={this.state.tags === null ? this.handleOpen : this.handleConfirmClose}>
                {this.state.tags === null ? (
                  <i className="scmty-plus" />
                ) : (
                  <i className="scmty-q-remove" />
                )}
              </button>
            </div>
            <CSSTransition in={this.state.tags !== null} timeout={300} classNames="fade" mountOnEnter unmountOnExit appear>
              <div className="panel-body poll-body hide">
                <TransitionGroup className="fade-inline" appear>
                  {this.state.tags &&
                    this.state.tags.map((tag) => (
                      <CSSTransition key={`tag_${tag.name}`} timeout={300} classNames="fade">
                        <div className="chip" style={{backgroundColor: tag.color}}>
                          <span>{tag.name}</span>
                          {tag.active === undefined || tag.active === true ? (
                            <button
                              title={this.props.intl.formatMessage(messages.delete)}
                              className="chip-remove"
                              type="button"
                              onClick={this.handleRemove(tag.name)}
                            />
                          ) : null}
                        </div>
                      </CSSTransition>
                    ))}
                  <CSSTransition key="autocomplete" in={this.state.tags !== null} timeout={300} classNames="fade">
                    <Autosuggest
                      suggestions={this.state.suggestions}
                      onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                      onSuggestionSelected={this.handleSuggestionSelected}
                      highlightFirstSuggestion
                      focusInputOnSuggestionClick={!Q.s('isMobile')}
                      getSuggestionValue={(suggestion) => suggestion.name}
                      shouldRenderSuggestions={Addressing.shouldRenderSuggestions}
                      renderSuggestionsContainer={Addressing.renderSuggestionsContainer}
                      renderSuggestion={Addressing.renderSuggestion}
                      inputProps={suggestionProps}
                      ref={this.storeAutowhateverRef}
                    />
                  </CSSTransition>
                </TransitionGroup>
                <div className="help-block fade hide" aria-hidden="true" />
              </div>
            </CSSTransition>
          </div>
          <ConfirmModal
            open={this.state.confirm !== null}
            title={this.props.intl.formatMessage(messages.modalTitle)}
            body={this.props.intl.formatMessage(messages.modalBody)}
            onConfirm={this.state.confirm}
            onClose={() => this.setState({confirm: null})}
          />
        </div>
      </CSSTransition>
    );
  }
}

export default injectIntl(Addressing);
