import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContentEditable from 'react-contenteditable';
import {CSSTransition} from 'react-transition-group';
import {defineMessages, injectIntl} from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'post.title.placeholder',
    defaultMessage: 'post.title.placeholder'
  },
  openPlaceholder: {
    id: 'post.title.openPlaceholder',
    defaultMessage: 'post.title.openPlaceholder'
  }
});

class Title extends Component {
  static defaultProps = {
    visible: false,
    maxLength: 160,
    minLength: 1,
    defaultValue: '',
    error: null,
    onFocus: null,
    onChange: null,
    onError: null
  };

  static propTypes = {
    visible: PropTypes.bool,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    defaultValue: PropTypes.string,
    error: PropTypes.object,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onError: PropTypes.func,

    /* Intl */
    intl: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      value: this.props.defaultValue,
      count: this.props.maxLength - this.props.defaultValue.length,
      caret: null
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePaste = this.handlePaste.bind(this);

    this.editableRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.value !== this.state.value && this.state.caret !== null) || (prevState.caret == null && this.state.caret !== null)) {
      const {caret} = this.state;
      window.RTU.setCaretAt(this.editableRef.current, caret);
    }
    if ((this.props.onError && prevState.count < 0 && this.state.count >= 0) || (prevState.count >= 0 && this.state.count < 0)) {
      this.props.onError(this.state.count < 0);
    }
  }

  /**
   * Handler for focus on title input.
   *
   * BEHAVIOUR:
   * Show and initialize the mediaBox, tagsBox, btnBox
   *
   * @param evt
   * @private
   */
  handleFocus() {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
    this.setState({caret: this.props.defaultValue.length});
  }

  /**
   * Handler for keydown into the title box
   *
   * BEHAVIOUR:
   * Show the counter of remaining chars and the link for the details editor
   *
   * @param evt
   * @private
   */
  handleKeydown(event) {
    if (event.keyCode === 13) {
      // Prevent enter keycode because question title cannot contain break spaces
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  handlePaste(event) {
    event.preventDefault();
    let range = window.RTU.getCurrentRange();
    if (range.collapsed) {
      const offset = window.RTU.getCaretOffset(this.editableRef.current);
      range = {
        collapsed: true,
        startOffset: offset,
        endOffset: offset
      };
    } else {
      // When range is on em elements then fix offsets
      range = {
        collapsed: false,
        startOffset: range.startOffset + (range.startContainer.parentNode.nodeName === 'EM' ? this.props.maxLength : 0),
        endOffset: range.endOffset + (range.endContainer.parentNode.nodeName === 'EM' ? this.props.maxLength : 0)
      };
    }
    const {value} = this.state;
    let paste = event.clipboardData
      .getData('text/plain')
      .replace(/(<([^>]+)>)/gi, ' ')
      .replace(/\s+/g, ' ')
      .trim();
    let caret = (range.collapsed ? range.endOffset : range.startOffset) + paste.length;
    let newValue = value.replace('<em>', '').replace('</em>', '');
    newValue = [newValue.slice(0, range.startOffset), paste, newValue.slice(range.endOffset)].join('');
    const remainingChars = this.props.maxLength - newValue.length;
    if (remainingChars < 0 && !Q.s('isMobile')) {
      newValue = newValue.substring(0, this.props.maxLength) + '<em>' + newValue.substring(this.props.maxLength) + '</em>';
    }
    this.setState({value: newValue, count: remainingChars, caret});
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  }

  handleChange(event) {
    let value = event.target.value.replace('<em>', '').replace('</em>', '');
    const remainingChars = this.props.maxLength - value.length;
    let caret = null;
    if (remainingChars < 0 && !Q.s('isMobile')) {
      value = value.substring(0, this.props.maxLength) + '<em>' + value.substring(this.props.maxLength) + '</em>';
      caret = window.RTU.getCaretOffset(this.editableRef.current);
    }
    this.setState({value, count: remainingChars, caret});
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    return (
      <div id="post_title" className={classNames('form-group clearfix', {'has-error': this.props.error})}>
        <div className="input-box">
          <input
            type="text"
            name="title"
            maxLength="160"
            minLength="1"
            placeholder={
              this.props.visible ? this.props.intl.formatMessage(messages.openPlaceholder) : this.props.intl.formatMessage(messages.placeholder)
            }
            autoComplete="off"
            data-rel="#id_title_textarea"
            className={classNames({hide: this.props.visible && !Q.s('isMobile')})}
            spellCheck="true"
            required=""
            id="id_title"
            value={this.state.value}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
          />
          <CSSTransition in={this.props.visible && !Q.s('isMobile')} timeout={300} classNames="fade-in" appear>
            <ContentEditable
              innerRef={this.editableRef}
              placeholder={this.props.intl.formatMessage(messages.openPlaceholder)}
              html={this.state.value} // innerHTML of the editable div
              disabled={false} // use true to disable edition
              onChange={this.handleChange} // handle innerHTML change
              onKeyDown={this.handleKeydown}
              onPaste={this.handlePaste} // handle paste
              className="ta hide"
            />
          </CSSTransition>
          <CSSTransition in={this.props.visible} timeout={300} classNames="fade" appear>
            <p className="meta clearfix pull-right hide">
              <span className="chars-count">{this.state.count}</span>
            </p>
          </CSSTransition>
          <CSSTransition in={Boolean(this.props.error)} classNames="fade" timeout={300}>
            <div className="help-block hide" aria-hidden="true">
              {this.props.error ? this.props.error.error : null}
            </div>
          </CSSTransition>
        </div>
      </div>
    );
  }
}

export default injectIntl(Title);
