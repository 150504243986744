import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

class Tooltip extends React.Component {
  static propTypes = {
    /* Other props */
    continuous: PropTypes.bool,
    isLastStep: PropTypes.bool,
    index: PropTypes.number,
    step: PropTypes.object,
    size: PropTypes.number,
    backProps: PropTypes.object,
    // skipProps: PropTypes.object,
    closeProps: PropTypes.object,
    primaryProps: PropTypes.object,
    tooltipProps: PropTypes.object,
    onGoToStep: PropTypes.func
  };

  static defaultProps = {};

  render() {
    const {continuous, isLastStep, index, step, backProps, closeProps, primaryProps, tooltipProps, size} = this.props;
    return (
      <div className={classNames('tooltipJr', {['tooltipJrR']: false})} {...tooltipProps}>
        <button className={'btnClose'} title="Close" onClick={closeProps.onClick} type="button">
          <i className="scmty-cross"></i>
        </button>
        <div className={'content'}>{step['content']}</div>
        <div className={classNames('footer', {['centered']: size === 1})}>
          <div>
            {index == 0 && size > 1 && (
              <button className={'btn-sm btn btn-default btnRemindMeLater'} onClick={closeProps.onClick} type="button">
                <FormattedMessage id="common.remindMeLater" defaultMessage="common.remindMeLater" />
              </button>
            )}
            {index > 0 && (
              <button className={'btn-sm btn btn-default bt-ct-previous'} {...backProps} type="button" onClick={backProps.onClick}>
                <FormattedMessage id="common.previous" defaultMessage="common.previous" />
              </button>
            )}
          </div>
          <div>
            {size > 1 && (
              <span style={{padding: 7}}>
                {index + 1}/{size}
              </span>
            )}
          </div>
          <div>
            {continuous && (
              <button className={'btn btn-primary btn-sm bt-ct-next'} onClick={primaryProps.onClick} type="button">
                {isLastStep ? (
                  <FormattedMessage id="common.close" defaultMessage="common.close" />
                ) : (
                  <FormattedMessage id="common.stepperNext" defaultMessage="common.stepperNext" />
                )}
              </button>
            )}
            {!continuous && (
              <button className={'btn btn-primary btn-sm'} {...closeProps} type="button">
                <FormattedMessage id="common.close" defaultMessage="common.close" />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Tooltip;
