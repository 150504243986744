/**
 *  Enable scroll
 */
export function enableScroll() {
  const _$body = $('body');
  _$body.removeClass('stop-scrolling');
  _$body.unbind('touchmove');
}

/**
 *  Disable scroll
 */
export function disableScroll() {
  const _$body = $('body');
  _$body.addClass('stop-scrolling');
  _$body.bind('touchmove', function (e) {
    e.preventDefault();
  });
}
